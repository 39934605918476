import React, { useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faVolleyball } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./header.css";

const Header = (props) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    setDropdownOpen(false);
  };

  const onClickToProfile = useCallback(() => {
    navigate(`/profile`);
  }, [navigate]);

  return (
    <div
      className="bg-main-color bg-light border-bottom shadow"
      style={{
        padding: "20px 20px",
      }}
    >
      <div className="row">
        <div className="col-4 d-flex flex-column align-items-center">
          <img
            src="assets/NewMiniapp/logo-3.png"
            alt="Profile Icon"
            style={{ width: "110px", height: "28px", marginBottom: "7px" }}
          />
          <span style={{ fontSize: "10px", color: "#333" }}>
            Digital Health
          </span>
        </div>

        <div className="col-4" onClick={onClickToProfile}>
          <div className="d-flex justify-content-center align-items-center mt-2">
            <div className="">
              <FontAwesomeIcon
                icon={faUser}
                style={{
                  width: "25px",
                  height: "25px",
                  color: "#BED308",
                }}
              />
            </div>
            <span className="ms-2 text-black">{t("profile")}</span>
          </div>
        </div>

        <div className="col-4 d-flex justify-content-end align-items-center">
          <FontAwesomeIcon
            icon={faVolleyball}
            style={{
              width: "25px",
              height: "25px",
              color: "#BED308",
            }}
          />
          <div className="custom-dropdown">
            <div
              className="custom-dropdown-header"
              onClick={() => setDropdownOpen(!dropdownOpen)}
              style={{ cursor: "pointer" }}
            >
              {t("selectedLanguage")}
            </div>
            {dropdownOpen && (
              <div className="custom-dropdown-menu">
                <div
                  className="custom-dropdown-item"
                  onClick={() => handleLanguageChange("en")}
                >
                  English
                </div>
                <div
                  className="custom-dropdown-item"
                  onClick={() => handleLanguageChange("ps")}
                >
                  پشتو
                </div>
                <div
                  className="custom-dropdown-item"
                  onClick={() => handleLanguageChange("fa")}
                >
                  دری
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
