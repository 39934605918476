import React, { useEffect, useRef, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faMicrophone,
  faMicrophoneSlash,
  faVideoSlash,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { ref, update } from "firebase/database";
import { database } from "../firebase/firebaseConfig";
import AgoraRTC from "agora-rtc-sdk-ng";
import "./video-call.css";

const VideoCall = () => {
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const localAudioRef = useRef(null);
  const ringingRef = useRef(new Audio("/ringtone.mp3"));
  const ringTimeoutRef = useRef(null);
  const [client, setClient] = useState(null);
  const [joined, setJoined] = useState(false);
  const [ringing, setRinging] = useState(true);
  const [audioMuted, setAudioMuted] = useState(false);
  const [videoMuted, setVideoMuted] = useState(false);
  const [cname, setDoctorEmail] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.state && location.state.email) {
      console.log('doctor channel' + location.state.email);
      setDoctorEmail(location.state.email);
    } else {
      console.error("Doctor email not available in location state.");
    }
  }, [location]);
  const session = JSON.parse(sessionStorage.getItem("userData"))
  // const senderEmail = "03037525634@digitalhealth.com";
  const senderEmail = `${session.userMsisdn}@digitalhealth.com`;
  const appointmentId = session.appointmentId;

  // Ensure cname is not null before calling .replace
  const email = cname ? cname.replace(/\./g, "") : null;

  const clickVideoCall = useCallback(async () => {
    // Stop local video and audio tracks
    if (localVideoRef.current) {
      const localVideoTrack =
        localVideoRef.current.srcObject.getVideoTracks()[0];
      if (localVideoTrack) {
        localVideoTrack.stop();
      }
    }

    if (localAudioRef.current) {
      const localAudioTrack =
        localAudioRef.current.srcObject.getAudioTracks()[0];
      if (localAudioTrack) {
        localAudioTrack.stop();
      }
    }

    ringingRef.current.pause(); // Stop the ringing tone
    setJoined(false);

    try {
      const callEntryData = {
        AppointmentID: appointmentId,
        CallType: "Incoming Video Call",
        IsCalling: "",
        SenderEmail: senderEmail,
      };
      await update(ref(database, `DoctorCall/${email}`), callEntryData);

      const doctorStatusUpdate = {
        status: "online",
      };
      await update(ref(database, `Doctors/${email}`), doctorStatusUpdate);
      sessionStorage.setItem("redirectFromVideoCall", "true");
      // window.location.href = `/?userMsisdn=${session.userMsisdn}`;
      window.location.href = `/dashboard`;
      // navigate(`/?userMsisdn=${session.userMsisdn}`);
    } catch (error) {
      console.error("Error making call:", error);
      // Handle error
    }
  }, [senderEmail]);

  const token = "f0924810ffd04733b7a726cb961157cd";

  useEffect(() => {
    if (!token) return;
    const agoraClient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
    setClient(agoraClient);
    // Subscribe to remote user's audio track
    agoraClient.on("user-published", async (user, mediaType) => {
      await agoraClient.subscribe(user, mediaType);
      console.log("User subscribed:", user);
      setRinging(false);
      if (mediaType === "video") {
        const remoteVideoTrack = user.videoTrack;
        remoteVideoTrack.play(remoteVideoRef.current);
      } else if (mediaType === "audio") {
        const remoteAudioTrack = user.audioTrack;
        remoteAudioTrack.play();
      }
      ringingRef.current.pause();
      clearTimeout(ringTimeoutRef.current);
    });

    agoraClient.on("user-left", (user) => {
      console.log("User unpublished:", user);
      sessionStorage.setItem("redirectFromVideoCall", "true");
      // window.location.href = `/?userMsisdn=${session.userMsisdn}`;
      window.location.href = `/dashboard`;
      // navigate(`/?userMsisdn=${session.userMsisdn}`);
    });

    const joinChannel = async () => {
      try {
        await agoraClient.join(token, cname, null, null);
        setJoined(true);
        console.log("Successfully joined the channel");
        const localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
        const localVideoTrack = await AgoraRTC.createCameraVideoTrack();
        localVideoTrack.play(localVideoRef.current);
        await agoraClient.publish([localAudioTrack, localVideoTrack]);

        // Play the ringing tone
        ringingRef.current.loop = true;
        ringingRef.current.play();

        // Set a timer to end the call after 30 seconds if not answered
        ringTimeoutRef.current = setTimeout(() => {
          clickVideoCall();
        }, 50000);
      } catch (error) {
        console.error("Failed to join the channel:", error);
      }
    };

    const makeCallEntryForFirebase = async (email, calling, status) => {
      try {
        const callEntryData = {
          AppointmentID: appointmentId,
          CallType: "Incoming Video Call",
          IsCalling: calling,
          CallingPlatform: "Afghan Telecom",
          SenderEmail: senderEmail,
        };
        await update(ref(database, `DoctorCall/${email}`), callEntryData);

        const doctorStatusUpdate = {
          status: status,
        };
        await update(ref(database, `Doctors/${email}`), doctorStatusUpdate);
      } catch (error) {
        console.error("Error making call:", error);
        // Handle error
      }
    };
    joinChannel();
    makeCallEntryForFirebase(email, "true", "busy");
  }, [token, cname]);

  const toggleAudioMute = () => {
    if (client) {
      const localAudioTrack = client.localTracks.find(
        (track) => track.trackMediaType === "audio"
      );
      if (localAudioTrack) {
        if (audioMuted) {
          localAudioTrack.setEnabled(true);
        } else {
          localAudioTrack.setEnabled(false);
        }
        setAudioMuted(!audioMuted);
      }
    }
  };

  const toggleVideoMute = () => {
    if (client) {
      const localVideoTrack = client.localTracks.find(
        (track) => track.trackMediaType === "video"
      );
      if (localVideoTrack) {
        if (videoMuted) {
          localVideoTrack.setEnabled(true);
        } else {
          localVideoTrack.setEnabled(false);
        }
        setVideoMuted(!videoMuted);
      }
    }
  };

  return (
    <>
      <div className="video-call-container position-fixed" style={{backgroundColor: "#393b3c"}}>
        <video
          ref={remoteVideoRef}
          className="video-container"
          autoPlay
          playsInline
          muted
        />
        <video
          ref={localVideoRef}
          className="position-absolute top-0 end-0 m-3"
          style={{ width: "200px", height: "150px" }}
          autoPlay
          playsInline
        />

        {ringing && (
          <div className="position-absolute top-50 start-50 bottom-0 translate-middle-x d-flex justify-content-center">
            <p style={{ color: "rgb(255 255 255)" }}>Ringing...</p>
          </div>
        )}

        <div className="position-absolute bottom-0 start-50 translate-middle-x d-flex justify-content-center" style={{marginBottom: "30%"}}>
          <button
            className="btn btn-primary me-2 btn-round"
            onClick={toggleAudioMute}
          >
            <FontAwesomeIcon
              icon={audioMuted ? faMicrophoneSlash : faMicrophone}
            />
          </button>
          <button
            className="btn btn-danger me-2 btn-round"
            onClick={clickVideoCall}
          >
            <FontAwesomeIcon icon={faPhone} />
          </button>
          <button
            className="btn btn-success btn-round"
            onClick={toggleVideoMute}
          >
            <FontAwesomeIcon icon={videoMuted ? faVideoSlash : faVideo} />
          </button>
        </div>
      </div>
    </>
  );
};

export default VideoCall;
