import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import "./icon.css";

const PackageCard = (props) => {
  const { t } = useTranslation();
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [packageDetails, setPackageDetails] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Directly use the props.userData
    if (props.userData && props.userData.featuresPackage) {
      setIsSubscribed(true);
      setPackageDetails(props.userData.featurePackageDetails);
    } else {
      setIsSubscribed(false);
      setPackageDetails(null);
    }
  }, [props.userData]);

  const clickToFeaturePackage = useCallback(() => {
    navigate("/feature-package");
  }, [navigate]);

  const clickToUnsubscribe = useCallback(async () => {
    Swal.fire({
      title: t("confirmUnsubscribeTitle"),
      text: t("confirmUnsubscribeText"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("confirmButton"),
      cancelButtonText: t("cancelButton"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const user = JSON.parse(sessionStorage.getItem("userData")) || {};
        const mobileNumber = user.userMsisdn;
        try {
          const response = await fetch(
            "https://bankalfalah.webddocsystems.com/afghan.php/unsubscribe",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                msisdn: mobileNumber,
                serviceTag: "WEBDOC_DH",
              }),
            }
          );
          const responseData = await response.json();
          if (!response.ok) {
            throw new Error(responseData.message);
          }
          if (responseData.code !== 0) {
            if (responseData.message === "Already Unsubscribed") {
              user.featuresPackage = false;
              sessionStorage.setItem("userData", JSON.stringify(user));
              setIsSubscribed(false);
              setPackageDetails(null);
              await handleUnSubscribe();
            } else {
              Swal.fire({
                icon: "warning",
                text: responseData.message,
                confirmButtonText: t("okButton"),
              });
            }
          } else {
            Swal.fire({
              icon: "success",
              title: t("unsubscribedTitle"),
              // text: responseData.message,
              confirmButtonText: t("okButton"),
            });
            user.featuresPackage = false;
            sessionStorage.setItem("userData", JSON.stringify(user));
            setIsSubscribed(false);
            setPackageDetails(null);
            // await handleUnSubscribe();
          }
        } catch (error) {
          console.error("Error unsubscribing:", error);
          Swal.fire({
            icon: "error",
            title: t("errorTitle"),
            text: t("errorMessage"),
            confirmButtonText: t("okButton"),
          });
        }
      }
    });
  }, [t]);

  const handleUnSubscribe = useCallback(async () => {
    try {
      const user = JSON.parse(sessionStorage.getItem("userData")) || {};
      const sessionUserId = user.applicationUserId;

      const response = await fetch(
        `https://bundleapi.webddocsystems.com/api/Account/Unsubscribe?patientid=${sessionUserId}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      if (data.responseCode === "0000") {
      } else {
        console.error("API response error:", data.message);
      }
    } catch (error) {
      console.error("Error activating subscription:", error);
    }
  });

  return (
    <div className="my-icon mt-2">
      <div className="card text-center shadow rounded icon-card-1 position-relative">
        {isSubscribed && (
          <span className="badge badge-success position-absolute top-0 end-0 m-2">
            {t("active")}
          </span>
        )}
        <div className="row no-gutters p-3">
          <div className="col-4 d-flex align-items-center justify-content-center">
            <img
              src="assets/NewMiniapp/pkg-card.png"
              className="card-img"
              alt="Package"
            />
          </div>
          <div className="col-8">
            <div
              className={`card-body ${
                isSubscribed ? "card-body-active" : "card-body-inactive"
              }`}
            >
              {isSubscribed ? (
                <>
                  <div className="row text-start" style={{ fontSize: "12px" }}>
                    <div className="col-12">
                      <span className="card-text text-white">
                        {t("activation_date")} :
                      </span>
                      <span className="text-white">
                        {packageDetails.activeDate}
                      </span>
                    </div>
                    <div className="col-12">
                      <span className="card-text text-white">
                        {t("next_charge_date")} :
                      </span>
                      <span className="text-white">
                        {packageDetails.nextChargeDare}
                      </span>
                    </div>
                    <button
                      className="btn btn-danger mt-2"
                      onClick={clickToUnsubscribe}
                      style={{ fontSize: "12px" }}
                    >
                      {t("unsubscribeButton")}
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <p className="card-text text-white">
                    {t("avail_service")} <br />{" "}
                    <strong>{t("daily_rate")}</strong>
                  </p>
                  <button
                    className="btn subscribe-button-1 p-2 text-decoration-none"
                    onClick={clickToFeaturePackage}
                  >
                    {t("subscribe_now")}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageCard;

// import React, { useState, useEffect, useCallback } from "react";
// import { useNavigate } from "react-router-dom";
// import "./icon.css";

// const PackageCard = () => {
//   const [isSubscribed, setIsSubscribed] = useState(false);
//   const [packageDetails, setPackageDetails] = useState(null);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const user = JSON.parse(sessionStorage.getItem("userData")) || {};
//     if (user.featuresPackage) {
//       setIsSubscribed(true);
//       setPackageDetails(user.featurePackageDetails);
//     }
//   }, []);

//   const clickToFeaturePackage = useCallback(() => {
//     navigate("/feature-package");
//   });

//   return (
//     <div className="my-icon mt-2">
//       <div
//         className="card text-center shadow rounded icon-card-1 position-relative"
//         style={{ backgroundColor: "" }}
//       >
//         {isSubscribed && (
//           <span className="badge badge-success position-absolute top-0 end-0 m-2">
//             Active
//           </span>
//         )}
//         <div className="row no-gutters p-3">
//           <div className="col-4 d-flex align-items-center justify-content-center">
//             <img
//               src="assets/NewMiniapp/pkg-card.png"
//               className="card-img"
//               alt="Doctor"
//             />
//           </div>
//           <div className="col-8">
//             <div
//               className={`card-body ${
//                 isSubscribed ? "card-body-active" : "card-body-inactive"
//               }`}
//             >
//               {isSubscribed ? (
//                 <>
//                   <span className="card-text text-white">Activation Date</span>
//                   <br></br>
//                   <span className="text-white">
//                     {packageDetails.activeDate}
//                   </span>
//                   <br></br>
//                   <br></br>
//                   <span className="card-text text-white">Next Charge Date</span>
//                   <br></br>
//                   <span className="text-white">
//                     {packageDetails.nextChargeDare}
//                   </span>
//                 </>
//               ) : (
//                 <>
//                   <p className="card-text text-white">
//                     Avail This Service in <br /> <strong>5 AFN/Daily</strong>
//                   </p>
//                   <button
//                     className="btn subscribe-button-1 p-2 text-decoration-none"
//                     onClick={clickToFeaturePackage}
//                   >
//                     Subscribe Now!
//                   </button>
//                 </>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PackageCard;
