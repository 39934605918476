import React, { useCallback } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Header_packages from "../components/Header_packages";
import { useTranslation } from "react-i18next"; // Import useTranslation

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #f3f4f6, #dfe9f3);
  font-family: "Roboto", sans-serif;
`;

const Header = styled.div`
  width: 100%;
  background: rgb(113 158 25);
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  padding: 90px 20px;
  text-align: center;
  color: white;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
`;

const ProfileContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -25px; // Overlap header with profile content
  width: 90%;
  max-width: 700px;
  background: white;
  border-radius: 20px;
  box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.15);
  padding: 40px 30px;
  text-align: center;
`;

const Avatar = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 5px solid #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
`;

const UserName = styled.h1`
  font-size: 28px;
  color: #333;
  margin-bottom: 10px;
`;

const Bio = styled.p`
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
  max-width: 500px;
`;

const EditButton = styled.button`
  background: rgb(113 158 25);
  color: white;
  border: none;
  border-radius: 30px;
  padding: 12px 25px;
  font-size: 18px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
  &:hover {
    background: #0056b3;
    transform: scale(1.05);
  }
`;

const ProfilePage = () => {
  const { t } = useTranslation(); // Initialize useTranslation
  const navigate = useNavigate();
  const session = JSON.parse(sessionStorage.getItem("userData"));
  console.log(session);

  const onClickToProfile = useCallback(
    (name, email, profilePic) => {
      if (email) {
        navigate(`/profile-edit`, { state: { name, email, profilePic } });
      } else {
        console.error("There is no profile data.");
      }
    },
    [navigate, session]
  );

  return (
    <Container>
      <Header_packages title={t("profilePageTitle")} />
      <Header />
      <ProfileContent>
        <Avatar
          src="/assets/NewMiniapp/dummy-user.png"
          alt={t("profilePageTitle")}
        />
        <UserName>{session.userName}</UserName>
        <Bio>
          {session.userMsisdn}
          <br />
          {/* {session.userEmail} */}
        </Bio>
        <div
          onClick={() => {
            onClickToProfile(
              session.userName,
              session.userEmail,
              "/assets/NewMiniapp/dummy-user.png"
            );
          }}
        >
          {/* <EditButton>{t("editProfile")}</EditButton> */}
        </div>
      </ProfileContent>
    </Container>
  );
};

export default ProfilePage;

// import React, { useCallback } from "react";
// import styled from "styled-components";
// import { useNavigate } from "react-router-dom";
// import Header_packages from "../components/Header_packages";

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   min-height: 100vh;
//   background: linear-gradient(135deg, #f3f4f6, #dfe9f3);
//   font-family: "Roboto", sans-serif;
// `;

// const Header = styled.div`
//   width: 100%;
//   background: rgb(113 158 25);
//   border-bottom-left-radius: 40px;
//   border-bottom-right-radius: 40px;
//   padding: 90px 20px;
//   text-align: center;
//   color: white;
//   box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
// `;

// const ProfileContent = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   margin-top: -25px; // Overlap header with profile content
//   width: 90%;
//   max-width: 700px;
//   background: white;
//   border-radius: 20px;
//   box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.15);
//   padding: 40px 30px;
//   text-align: center;
// `;

// const Avatar = styled.img`
//   width: 150px;
//   height: 150px;
//   border-radius: 50%;
//   border: 5px solid #ffffff;
//   box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
//   margin-bottom: 20px;
// `;

// const UserName = styled.h1`
//   font-size: 28px;
//   color: #333;
//   margin-bottom: 10px;
// `;

// const Bio = styled.p`
//   font-size: 16px;
//   color: #666;
//   margin-bottom: 20px;
//   max-width: 500px;
// `;

// const EditButton = styled.button`
//   background: rgb(113 158 25);
//   color: white;
//   border: none;
//   border-radius: 30px;
//   padding: 12px 25px;
//   font-size: 18px;
//   cursor: pointer;
//   transition: background 0.3s ease, transform 0.3s ease;
//   &:hover {
//     background: #0056b3;
//     transform: scale(1.05);
//   }
// `;

// const ProfilePage = () => {
//   const navigate = useNavigate();
//   const session = JSON.parse(sessionStorage.getItem("userData"));
//   console.log(session);
//   const onClickToProfile = useCallback(
//     (name, email, profilePic) => {
//       if (email) {
//         navigate(`/profile-edit`, { state: { name, email, profilePic } });
//       } else {
//         console.error("There is no profile data.");
//       }
//     },
//     [navigate, session]
//   );
//   return (
//     <Container>
//       <Header_packages title="Profile" />
//       <Header>
//       </Header>
//       <ProfileContent>
//         <Avatar src="/assets/NewMiniapp/dummy-user.png" alt="User Avatar" />
//         <UserName>{session.userName}</UserName>
//         <Bio>
//           {session.userMsisdn}
//           <br></br>
//           {session.userEmail}
//         </Bio>
//         <div
//           onClick={() => {
//             onClickToProfile(
//               session.userName,
//               session.userEmail,
//               "/assets/NewMiniapp/dummy-user.png"
//             );
//           }}
//         >
//           {/* <EditButton>Edit Profile</EditButton> */}
//         </div>
//       </ProfileContent>
//     </Container>
//   );
// };

// export default ProfilePage;
