import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import styled from "styled-components";
import { useTranslation } from "react-i18next"; // Import useTranslation
import "../i18n"; // Import i18n configuration

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  font-family: "Roboto", sans-serif;
`;

const Header = styled.div`
  width: 100%;
  background: #719e19;
  padding: 20px;
  color: white;
  text-align: center;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
`;

const OTPContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const OTPInput = styled.input`
  width: 70px;
  height: 70px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  border-radius: 20px;
  border: 2px solid #719e19;
  color: #719e19;
  margin: 0 5px;
  outline: none;
  transition: border-color 0.3s ease;
  &:focus {
    border-color: #0056b3;
  }
`;

const ResendButton = styled.button`
  background: none;
  border: none;
  color: #719e19;
  cursor: pointer;
  font-size: 16px;
  transition: color 0.3s ease;
  &:hover {
    color: #0056b3;
  }
`;

const VerifyButton = styled.button`
  background-color: #719e19;
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: 12px 25px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  &:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const OTPImage = styled.img`
  max-width: 325px;
  margin: 20px 0;
`;

const MobileOTP = () => {
  const { t } = useTranslation(); // Initialize useTranslation
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileNumber, setMobileNumber] = useState(null);
  const [userPName, setUserName] = useState(null);
  const [loadingMore, setResend] = useState(false);
  const [verifying, setVerify] = useState(false);
  const [otpNumbers, setOtpNumbers] = useState(["", "", "", "", ""]);
  const inputsRef = useRef([]);

  const session = JSON.parse(sessionStorage.getItem("userData"));
  const sessionUserId = session?.Id || null;

  useEffect(() => {
    if (location.state && location.state.mobileNumber) {
      setMobileNumber(location.state.mobileNumber);
      setUserName(location.state.name);
    } else {
      console.error("Mobile number is not available in location state.");
    }
  }, [location.state]);

  const onDidntReceiveTheClick = useCallback(async () => {
    try {
      setResend(true);
      const response = await fetch(
        "https://testingnew.webddocsystems.com/public/api/v1/js/debit-inquery",
        {
          method: "POST",
          body: JSON.stringify({ mobileNumber: mobileNumber }),
        }
      );
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.message);
      }
      if (responseData.statusCode === 3) {
        Swal.fire({
          icon: "warning",
          title: t("enterOTP"),
          text: responseData.statusMessage?.[0],
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
    } finally {
      setResend(false);
    }
  }, [mobileNumber, t]);

  const authenticateUser = async (userMsisdn, userPName) => {
    try {
      const response = await axios.post(
        "https://bundleapi.webddocsystems.com/api/Account/Register",
        {
          mobileNumber: userMsisdn,
          customerName: userPName,
          gender: "male",
        }
      );

      const user = {
        userMsisdn: response.data.patientProfile.mobileNumber,
        userName: response.data.patientProfile.name,
        applicationUserId: response.data.patientProfile.id,
        userEmail: `${response.data.patientProfile.mobileNumber}@webdoc.com.pk`,
        package: response.data.patientProfile.isPackageActivated,
        featuresPackage: response.data.patientProfile.isFeaturePackageActivated,
        headOfGreeting: response.data.patientProfile.headOfGreeting,
        contentOfGreeting: response.data.patientProfile.contentOfGreeting,
        appointmentId: response.data.patientProfile.appointmentid,
        featurePackageDetails:
          response.data.patientProfile.featurePackageDetails,
        message: "User registered and authenticated successfully.",
      };
      sessionStorage.setItem("userData", JSON.stringify(user));
      // Optional: Use context or state management to update user data across components
    } catch (error) {
      console.error("Error registering user:", error);
    }
  };

  const onVerifyTextClick = useCallback(async () => {
    try {
      setVerify(true);
      const otp = otpNumbers.join("");
      const response = await fetch(
        "https://testingnew.webddocsystems.com/public/api/v1/js/debit-payment",
        {
          method: "POST",
          body: JSON.stringify({
            mobileNumber: mobileNumber,
            otpPin: otp,
            profileId: sessionUserId,
          }),
        }
      );
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.statusMessage);
      }
      switch (responseData.statusCode) {
        case 3:
          await authenticateUser(mobileNumber, userPName);
          navigate(`/dashboard`);
          break;
        case 1:
          navigate("/payment-success");
          break;
        default:
          console.warn("Unhandled statusCode:", responseData.statusCode);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      Swal.fire({
        icon: "error",
        // title: t("verificationFailed"),
        text: t("verificationFailed"),
      });
    } finally {
      setVerify(false);
    }
  }, [navigate, mobileNumber, otpNumbers, sessionUserId, userPName, t]);

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const updatedOtpNumbers = [...otpNumbers];
    if (/^\d*$/.test(value)) {
      updatedOtpNumbers[index] = value;
      setOtpNumbers(updatedOtpNumbers);
    }
    if (index < otpNumbers.length - 1 && value.length === 1) {
      inputsRef.current[index + 1]?.focus();
    }
  };

  const handleBackspace = (index, e) => {
    if (e.keyCode === 8 && index > 0 && !otpNumbers[index]) {
      const updatedOtpNumbers = [...otpNumbers];
      updatedOtpNumbers[index - 1] = "";
      setOtpNumbers(updatedOtpNumbers);
      inputsRef.current[index - 1]?.focus();
    }
  };

  // Check if all OTP fields are filled
  const isAllOtpFilled = otpNumbers.every((num) => num !== "");

  return (
    <Container>
      <Header>
        <h1>{t("enterOTP")}</h1>
      </Header>
      <div className="text-center mb-4">
        <OTPImage src="/assets/enterotp-1@2x.png" alt={t("otpImageAlt")} />
      </div>
      <div className="text-center mb-4">
        <h4>{t("otpSentMessage")}</h4>
        <p className="text-muted">{t("otpSentToNumber", { mobileNumber })}</p>
      </div>
      <OTPContainer>
        {otpNumbers.map((otp, index) => (
          <OTPInput
            key={index}
            ref={(ref) => (inputsRef.current[index] = ref)}
            value={otp}
            onChange={(e) => handleInputChange(e, index)}
            onKeyDown={(e) => handleBackspace(index, e)}
            maxLength="1"
            type="tel"
          />
        ))}
      </OTPContainer>
      <div className="text-center mb-4">
        <ResendButton onClick={onDidntReceiveTheClick} disabled={loadingMore}>
          {loadingMore ? t("resendingButton") : t("resendButton")}
        </ResendButton>
      </div>
      <div className="text-center">
        <VerifyButton
          onClick={verifying || !isAllOtpFilled ? null : onVerifyTextClick}
          disabled={verifying || !isAllOtpFilled}
        >
          {verifying ? t("verifyingButton") : t("verifyButton")}
        </VerifyButton>
      </div>
    </Container>
  );
};

export default MobileOTP;

// import React, { useState, useEffect, useCallback, useRef } from "react";
// import axios from "axios";
// import { useNavigate, useLocation } from "react-router-dom";
// import Swal from "sweetalert2";
// import styled from "styled-components";

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   min-height: 100vh;
//   // background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
//   font-family: "Roboto", sans-serif;
// `;

// const Header = styled.div`
//   width: 100%;
//   background: #719e19;
//   padding: 20px;
//   color: white;
//   text-align: center;
//   border-bottom-left-radius: 40px;
//   border-bottom-right-radius: 40px;
//   box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
//   margin-bottom: 30px;
// `;

// const OTPContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   margin-bottom: 20px;
// `;

// const OTPInput = styled.input`
//   width: 70px;
//   height: 70px;
//   font-size: 24px;
//   font-weight: bold;
//   text-align: center;
//   border-radius: 20px;
//   border: 2px solid #719e19;
//   color: #719e19;
//   margin: 0 5px;
//   outline: none;
//   transition: border-color 0.3s ease;
//   &:focus {
//     border-color: #0056b3;
//   }
// `;

// const ResendButton = styled.button`
//   background: none;
//   border: none;
//   color: #719e19;
//   cursor: pointer;
//   font-size: 16px;
//   transition: color 0.3s ease;
//   &:hover {
//     color: #0056b3;
//   }
// `;

// const VerifyButton = styled.button`
//   background-color: #719e19;
//   border: none;
//   border-radius: 5px;
//   color: #fff;
//   padding: 12px 25px;
//   font-size: 18px;
//   cursor: pointer;
//   transition: background-color 0.3s ease, transform 0.3s ease;
//   &:hover {
//     background-color: #0056b3;
//     transform: scale(1.05);
//   }
//   &:disabled {
//     background-color: #ccc;
//     cursor: not-allowed;
//   }
// `;

// const OTPImage = styled.img`
//   max-width: 325px;
//   margin: 20px 0;
// `;

// const MobileOTP = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [mobileNumber, setMobileNumber] = useState(null);
//   const [userPName, setUserName] = useState(null);
//   const [loadingMore, setResend] = useState(false);
//   const [verifying, setVerify] = useState(false);
//   const [otpNumbers, setOtpNumbers] = useState(["", "", "", "", ""]);
//   const inputsRef = useRef([]);

//   const session = JSON.parse(sessionStorage.getItem("userData"));
//   let sessionUserId = session?.Id || null;

//   useEffect(() => {
//     if (location.state && location.state.mobileNumber) {
//       setMobileNumber(location.state.mobileNumber);
//       setUserName(location.state.name);
//     } else {
//       console.error("Mobile number is not available in location state.");
//     }
//   }, [location.state]);
//   const onDidntReceiveTheClick = useCallback(async () => {
//     try {
//       setResend(true);
//       const response = await fetch(
//         "https://testingnew.webddocsystems.com/public/api/v1/js/debit-inquery",
//         {
//           method: "POST",
//           body: JSON.stringify({ mobileNumber: mobileNumber }),
//         }
//       );
//       const responseData = await response.json();
//       if (!response.ok) {
//         throw new Error(responseData.message);
//       }
//       if (responseData.statusCode === 3) {
//         Swal.fire({
//           icon: "warning",
//           title: "OTP",
//           text: responseData.statusMessage?.[0],
//           confirmButtonText: "OK",
//         });
//       }
//     } catch (error) {
//       console.error("Error resending OTP:", error);
//     } finally {
//       setResend(false);
//     }
//   }, [mobileNumber]);
//   const authenticateUser = async (userMsisdn, userPName) => {
//     console.log(userPName);
//     try {
//       const response = await axios.post(
//         "https://bundleapi.webddocsystems.com/api/Account/Register",
//         {
//           mobileNumber: userMsisdn,
//           customerName: userPName,
//           gender: "male",
//         }
//       );

//       const user = {
//         userMsisdn: response.data.patientProfile.mobileNumber,
//         userName: response.data.patientProfile.name,
//         applicationUserId: response.data.patientProfile.id,
//         userEmail: `${response.data.patientProfile.mobileNumber}@webdoc.com.pk`,
//         package: response.data.patientProfile.isPackageActivated,
//         featuresPackage: response.data.patientProfile.isFeaturePackageActivated,
//         headOfGreeting: response.data.patientProfile.headOfGreeting,
//         contentOfGreeting: response.data.patientProfile.contentOfGreeting,
// appointmentId: response.data.patientProfile.appointmentid,
//         featurePackageDetails:
//           response.data.patientProfile.featurePackageDetails,
//         message: "User registered and authenticated successfully.",
//       };
//       sessionStorage.setItem("userData", JSON.stringify(user));
//       // setUserData(user);
//     } catch (error) {
//       console.error("Error registering user:", error);
//     }
//   };

//   const onVerifyTextClick = useCallback(async () => {
//     try {
//       setVerify(true);
//       const otp = otpNumbers.join("");
//       const response = await fetch(
//         "https://testingnew.webddocsystems.com/public/api/v1/js/debit-payment",
//         {
//           method: "POST",
//           body: JSON.stringify({
//             mobileNumber: mobileNumber,
//             otpPin: otp,
//             profileId: sessionUserId,
//           }),
//         }
//       );
//       const responseData = await response.json();
//       if (!response.ok) {
//         throw new Error(responseData.statusMessage);
//       }
//       switch (responseData.statusCode) {
//         case 3:
//           authenticateUser(mobileNumber, userPName);
//           // navigate(`/?userMsisdn=${user.userMsisdn}`);
//           navigate(`/dashboard`);
//           // navigate("/dashboard", { state: { mobileNumber } });
//           // Swal.fire({
//           //   icon: 'warning',
//           //   title: 'OTP',
//           //   text: responseData.statusMessage[0],
//           //   confirmButtonText: 'OK',
//           // });
//           break;
//         case 1:
//           navigate("/payment-success");
//           break;
//         default:
//           console.warn("Unhandled statusCode:", responseData.statusCode);
//       }
//     } catch (error) {
//       console.error("Error verifying OTP:", error);
//       Swal.fire({
//         icon: "error",
//         title: "Verification Failed",
//         text: "Failed to verify OTP. Please try again later.",
//       });
//     } finally {
//       setVerify(false);
//     }
//   }, [navigate, mobileNumber, otpNumbers, sessionUserId, userPName]);

//   const handleInputChange = (e, index) => {
//     const { value } = e.target;
//     const updatedOtpNumbers = [...otpNumbers];
//     if (/^\d*$/.test(value)) {
//       updatedOtpNumbers[index] = value;
//       setOtpNumbers(updatedOtpNumbers);
//     }
//     if (index < otpNumbers.length - 1 && value.length === 1) {
//       inputsRef.current[index + 1]?.focus();
//     }
//   };

//   const handleBackspace = (index, e) => {
//     if (e.keyCode === 8 && index > 0 && !otpNumbers[index]) {
//       const updatedOtpNumbers = [...otpNumbers];
//       updatedOtpNumbers[index - 1] = "";
//       setOtpNumbers(updatedOtpNumbers);
//       inputsRef.current[index - 1]?.focus();
//     }
//   };

//   // Check if all OTP fields are filled
//   const isAllOtpFilled = otpNumbers.every((num) => num !== "");

//   return (
//     <Container>
//       <Header>
//         <h1>Enter OTP</h1>
//       </Header>
//       <div className="text-center mb-4">
//         <OTPImage src="/assets/enterotp-1@2x.png" alt="Enter OTP" />
//       </div>
//       <div className="text-center mb-4">
//         <h4>A 5 digit code has been sent</h4>
//         <p className="text-muted">to number {mobileNumber}</p>
//       </div>
//       <OTPContainer>
//         {otpNumbers.map((otp, index) => (
//           <OTPInput
//             key={index}
//             ref={(ref) => (inputsRef.current[index] = ref)}
//             value={otp}
//             onChange={(e) => handleInputChange(e, index)}
//             onKeyDown={(e) => handleBackspace(index, e)}
//             maxLength="1"
//             type="tel"
//           />
//         ))}
//       </OTPContainer>
//       <div className="text-center mb-4">
//         <ResendButton onClick={onDidntReceiveTheClick} disabled={loadingMore}>
//           {loadingMore ? "Resending..." : "Didn’t receive the code? Resend"}
//         </ResendButton>
//       </div>
//       <div className="text-center">
//         <VerifyButton
//           onClick={verifying || !isAllOtpFilled ? null : onVerifyTextClick}
//           disabled={verifying || !isAllOtpFilled}
//         >
//           {verifying ? "Verifying..." : "Verify"}
//         </VerifyButton>
//       </div>
//     </Container>
//   );
// };

// export default MobileOTP;
