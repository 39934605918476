import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import Header_packages from "./Header_packages";
import { useLoading } from "../contexts/LoadingContext";
import { useTranslation } from "react-i18next";

const HealthTipsDetail = () => {
  const [searchParams] = useSearchParams();
  const category = searchParams.get("category");
  const [videos, setVideos] = useState([]);
  const [heading, setHeading] = useState("");
  const { isLoading, setIsLoading } = useLoading();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === "rtl";

  useEffect(() => {
    const fetchVideos = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `https://webservice.healthlineweb.com.pk/api/v1/GetVideosForWebView?serviceName=${category}&lang=${t(
            "apiLanguage"
          )}`,
          {
            headers: {
              Authorization:
                "Basic V0RITDpiN2E4MGY2NmY0MGM0MzA3OTU4M2VkZTQyZjM4OWY1OQ==",
            },
          }
        );
        setVideos(response.data.GetVideosForWebViewResult.Videos || []);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching videos:", error);
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
          console.error("Response headers:", error.response.headers);
        } else if (error.request) {
          console.error("Request:", error.request);
        } else {
          console.error("Error message:", error.message);
        }
      }
    };

    fetchVideos();

    switch (category) {
      case "diabetes":
        setHeading(t("diabetes"));
        break;
      case "Pelvic_Inflammatory":
        setHeading(t("pelvicInflammatory"));
        break;
      case "tuberculosis":
        setHeading(t("tuberculosis"));
        break;
      case "gastroenteritis":
        setHeading(t("gastroenteritis"));
        break;
      default:
        setHeading("");
    }
  }, [category, t, setIsLoading]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.youtube.com/iframe_api";
    document.body.appendChild(script);

    const onYouTubeIframeAPIReady = () => {
      window.YT.ready(() => {
        const players = [];
        document.querySelectorAll("iframe").forEach((iframe, index) => {
          const player = new window.YT.Player(iframe.id, {
            events: {
              onStateChange: (event) => {
                if (event.data === window.YT.PlayerState.PLAYING) {
                  players.forEach((p) => {
                    if (p !== event.target) {
                      p.stopVideo();
                    }
                  });
                }
              },
            },
          });
          players.push(player);
        });
      });
    };

    window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;

    return () => {
      document.body.removeChild(script);
    };
  }, [videos]);

  return (
    <>
      <Header_packages title={t("healthTipsTitle")} />
      <div
        className="container mt-5 pt-5"
        style={{ direction: isRTL ? "rtl" : "ltr" }}
      >
        <div className="row">
          <div className="col-12">
            <h4 className="heading">{heading}</h4>
            <p className={`body fs-5 pt-2 ${isRTL ? "text-end" : ""}`}></p>
          </div>
        </div>
        <div className="row">
          {videos.length === 0 ? (
            <div className="col-12 text-center">
              <p>{t("nothingToShow")}</p>
            </div>
          ) : (
            videos.map((video, index) => (
              <div
                className={`col-12 mb-4 p-0 ${isRTL ? "text-end" : ""}`}
                key={index}
              >
                <div className="card">
                  <div className="card-body">
                    {category === "diabetes" ||
                    category === "Pelvic_Inflammatory" ? (
                      <div></div>
                    ) : (
                      <div
                        className="embed-responsive embed-responsive-16by9"
                        style={{ height: "40vh" }}
                      >
                        <iframe
                          id={`video${index}`}
                          className="embed-responsive-item"
                          src={video.EmbeddedURL}
                          allowFullScreen
                          style={{ width: "100%", height: "100%" }}
                        ></iframe>
                      </div>
                    )}

                    <h3 className="card-title">{video.VideoTitle}</h3>
                    <p className="card-text text-muted">
                      <strong>{t("doctor")}:</strong> {video.DoctorName}
                    </p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: video.Description,
                      }}
                      className={isRTL ? "text-end" : ""}
                    />
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default HealthTipsDetail;

// import React, { useEffect, useState } from "react";
// import { useSearchParams } from "react-router-dom";
// import axios from "axios";
// import Header_packages from "./Header_packages";
// import { useLoading } from "../contexts/LoadingContext";
// import { useTranslation } from "react-i18next";

// const HealthTipsDetail = () => {
//   const [searchParams] = useSearchParams();
//   const category = searchParams.get("category");
//   const [videos, setVideos] = useState([]);
//   const [heading, setHeading] = useState("");
//   const { isLoading, setIsLoading } = useLoading();
//   const { t, i18n } = useTranslation();
//   const isRTL = i18n.dir() === "rtl";

//   useEffect(() => {
//     const fetchVideos = async () => {
//       setIsLoading(true);
//       try {
//         const response = await axios.get(
//           `https://webservice.healthlineweb.com.pk/api/v1/GetVideosForWebView?serviceName=${category}&lang=${t("apiLanguage")}`,
//           {
//             headers: {
//               Authorization: "Basic V0RITDpiN2E4MGY2NmY0MGM0MzA3OTU4M2VkZTQyZjM4OWY1OQ==",
//             },
//           }
//         );
//         // Only set videos if the category is not "diabetes" or "Pelvic_Inflammatory"
//         if (category !== "diabetes" && category !== "Pelvic_Inflammatory") {
//           setVideos(response.data.GetVideosForWebViewResult.Videos || []);
//         } else {
//           setVideos([]); // Ensure videos is empty for restricted categories
//         }
//         setIsLoading(false);
//       } catch (error) {
//         console.error("Error fetching videos:", error);
//         setIsLoading(false);
//       }
//     };

//     fetchVideos();

//     switch (category) {
//       case "diabetes":
//         setHeading(t("diabetes"));
//         break;
//       case "Pelvic_Inflammatory":
//         setHeading(t("pelvicInflammatory"));
//         break;
//       case "tuberculosis":
//         setHeading(t("tuberculosis"));
//         break;
//       case "gastroenteritis":
//         setHeading(t("gastroenteritis"));
//         break;
//       default:
//         setHeading("");
//     }
//   }, [category, t, setIsLoading]);

//   useEffect(() => {
//     const script = document.createElement("script");
//     script.src = "https://www.youtube.com/iframe_api";
//     document.body.appendChild(script);

//     const onYouTubeIframeAPIReady = () => {
//       window.YT.ready(() => {
//         const players = [];
//         document.querySelectorAll("iframe").forEach((iframe, index) => {
//           const player = new window.YT.Player(iframe.id, {
//             events: {
//               onStateChange: (event) => {
//                 if (event.data === window.YT.PlayerState.PLAYING) {
//                   players.forEach((p) => {
//                     if (p !== event.target) {
//                       p.stopVideo();
//                     }
//                   });
//                 }
//               },
//             },
//           });
//           players.push(player);
//         });
//       });
//     };

//     window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;

//     return () => {
//       document.body.removeChild(script);
//     };
//   }, [videos]);

//   return (
//     <>
//       <Header_packages title={t("healthTipsTitle")} />
//       <div
//         className="container mt-5 pt-5"
//         style={{ direction: isRTL ? "rtl" : "ltr" }}
//       >
//         <div className="row">
//           <div className="col-12">
//             <h4 className="heading">{heading}</h4>
//             <p className={`body fs-5 pt-2 ${isRTL ? "text-end" : ""}`}></p>
//           </div>
//         </div>
//         <div className="row">
//           {videos.length === 0 ? (
//             <div className="col-12 text-center">
//               <p>{category === "diabetes" || category === "Pelvic_Inflammatory" ? t("noVideosAvailable") : t("nothingToShow")}</p>
//             </div>
//           ) : (
//             videos.map((video, index) => (
//               <div
//                 className={`col-12 mb-4 p-0 ${isRTL ? "text-end" : ""}`}
//                 key={index}
//               >
//                 <div className="card">
//                   <div className="card-body">
//                     <div
//                       className="embed-responsive embed-responsive-16by9"
//                       style={{ height: "40vh" }}
//                     >
//                       <iframe
//                         id={`video${index}`}
//                         className="embed-responsive-item"
//                         src={video.EmbeddedURL}
//                         allowFullScreen
//                         style={{ width: "100%", height: "100%" }}
//                       ></iframe>
//                     </div>
//                     <h3 className="card-title">{video.VideoTitle}</h3>
//                     <p className="card-text text-muted">
//                       <strong>{t("doctor")}:</strong> {video.DoctorName}
//                     </p>
//                     <p
//                       dangerouslySetInnerHTML={{
//                         __html: video.Description,
//                       }}
//                       className={isRTL ? "text-end" : ""}
//                     />
//                   </div>
//                 </div>
//               </div>
//             ))
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// export default HealthTipsDetail;
