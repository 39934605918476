import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import Header_packages from "../components/Header_packages";
import { useTranslation } from "react-i18next"; // Import useTranslation

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
  font-family: "Roboto", sans-serif;
`;

const Header = styled.div`
  width: 100%;
  background: rgb(113 158 25);
  padding: 90px 20px;
  text-align: center;
  color: white;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
`;

const FormContainer = styled.div`
  width: 90%;
  max-width: 600px;
  background: white;
  border-radius: 20px;
  box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.15);
  padding: 40px 30px;
  margin-top: -25px;
  text-align: center;
`;

const FormField = styled.div`
  margin-bottom: 20px;
  text-align: left;
`;

const Label = styled.label`
  display: block;
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-sizing: border-box;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-sizing: border-box;
`;

const FileInput = styled.input`
  display: none;
`;

const AvatarWrapper = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
`;

const Avatar = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 4px solid #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
`;

const UploadIcon = styled.label`
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgb(113 158 25);
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  font-size: 20px;
  transition: background 0.3s ease;

  &:hover {
    background: rgb(113 200 77);
  }
`;

const SubmitButton = styled.button`
  background: rgb(113 158 25);
  color: white;
  border: none;
  border-radius: 30px;
  padding: 12px 25px;
  font-size: 18px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
  &:hover {
    background: #0056b3;
    transform: scale(1.05);
  }
`;

const ProfileEditPage = () => {
  const { t } = useTranslation(); // Initialize useTranslation
  const location = useLocation();
  const { state } = location;
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    bio: "",
  });
  const [profilePic, setProfilePic] = useState(null);
  const [file, setFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (state) {
      const { name, email, profilePic } = state;
      setFormData({ name, email, bio: "" });
      setProfilePic(profilePic);
    }
  }, [state]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      setProfilePic(URL.createObjectURL(selectedFile));
      setFile(selectedFile);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");

    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.name);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("bio", formData.bio);
    if (file) {
      formDataToSend.append("profilePic", file);
    }

    try {
      // Replace with your API endpoint
      await axios.put("https://api.example.com/profile", formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert(t("profileUpdateSuccess")); // Use translation key
    } catch (err) {
      setError(t("profileUpdateError")); // Use translation key
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container>
      <Header_packages title={t("profileEditPageTitle")} />
      <Header />
      <FormContainer>
        <form onSubmit={handleSubmit}>
          <AvatarWrapper>
            {profilePic && (
              <Avatar src={profilePic} alt={t("profileEditPageTitle")} />
            )}
            <UploadIcon htmlFor="profilePic">
              <FaPlus />
              <FileInput
                type="file"
                id="profilePic"
                name="profilePic"
                accept="image/*"
                onChange={handleFileChange}
              />
            </UploadIcon>
          </AvatarWrapper>
          <FormField>
            <Label htmlFor="name">{t("nameLabel")}</Label>
            <Input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </FormField>
          <FormField>
            <Label htmlFor="email">{t("emailLabel")}</Label>
            <Input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </FormField>
          <FormField>
            <Label htmlFor="bio">{t("bioLabel")}</Label>
            <TextArea
              id="bio"
              name="bio"
              value={formData.bio}
              onChange={handleChange}
              rows="4"
            />
          </FormField>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <SubmitButton type="submit" disabled={isSubmitting}>
            {isSubmitting
              ? t("updatingProfileButton")
              : t("updateProfileButton")}
          </SubmitButton>
        </form>
      </FormContainer>
    </Container>
  );
};

export default ProfileEditPage;

// // src/ProfileEditPage.js
// import React, { useState, useEffect } from "react";
// import styled from "styled-components";
// import axios from "axios";
// import { useLocation } from "react-router-dom";
// import { FaPlus } from "react-icons/fa";
// import Header_packages from "../components/Header_packages";

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   min-height: 100vh;
//   background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
//   font-family: "Roboto", sans-serif;
// `;

// const Header = styled.div`
//   width: 100%;
//   background: rgb(113 158 25);
//   padding: 90px 20px;
//   text-align: center;
//   color: white;
//   border-bottom-left-radius: 40px;
//   border-bottom-right-radius: 40px;
//   box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
// `;

// const FormContainer = styled.div`
//   width: 90%;
//   max-width: 600px;
//   background: white;
//   border-radius: 20px;
//   box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.15);
//   padding: 40px 30px;
//   margin-top: -25px;
//   text-align: center;
// `;

// const FormField = styled.div`
//   margin-bottom: 20px;
//   text-align: left;
// `;

// const Label = styled.label`
//   display: block;
//   font-size: 16px;
//   color: #333;
//   margin-bottom: 5px;
// `;

// const Input = styled.input`
//   width: 100%;
//   padding: 10px;
//   font-size: 16px;
//   border: 1px solid #ddd;
//   border-radius: 10px;
//   box-sizing: border-box;
// `;

// const TextArea = styled.textarea`
//   width: 100%;
//   padding: 10px;
//   font-size: 16px;
//   border: 1px solid #ddd;
//   border-radius: 10px;
//   box-sizing: border-box;
// `;

// const FileInput = styled.input`
//   display: none;
// `;

// const AvatarWrapper = styled.div`
//   position: relative;
//   display: inline-block;
//   margin-bottom: 20px;
// `;

// const Avatar = styled.img`
//   width: 120px;
//   height: 120px;
//   border-radius: 50%;
//   border: 4px solid #ffffff;
//   box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
// `;

// const UploadIcon = styled.label`
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   background: rgb(113 158 25);
//   color: white;
//   border-radius: 50%;
//   width: 40px;
//   height: 40px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
//   box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
//   font-size: 20px;
//   transition: background 0.3s ease;

//   &:hover {
//     background: rgb(113 200 77);
//   }
// `;

// const SubmitButton = styled.button`
//   background: rgb(113 158 25);
//   color: white;
//   border: none;
//   border-radius: 30px;
//   padding: 12px 25px;
//   font-size: 18px;
//   cursor: pointer;
//   transition: background 0.3s ease, transform 0.3s ease;
//   &:hover {
//     background: #0056b3;
//     transform: scale(1.05);
//   }
// `;

// const ProfileEditPage = () => {
//   const location = useLocation();
//   const { state } = location;
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     bio: "",
//   });
//   const [profilePic, setProfilePic] = useState(null);
//   const [file, setFile] = useState(null);
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [error, setError] = useState("");

//   useEffect(() => {
//     if (state) {
//       const { name, email, profilePic } = state;
//       setFormData({ name, email, bio: "" });
//       setProfilePic(profilePic);
//     }
//   }, [state]);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleFileChange = (e) => {
//     if (e.target.files.length > 0) {
//       const selectedFile = e.target.files[0];
//       setProfilePic(URL.createObjectURL(selectedFile));
//       setFile(selectedFile);
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setIsSubmitting(true);
//     setError("");

//     const formDataToSend = new FormData();
//     formDataToSend.append("name", formData.name);
//     formDataToSend.append("email", formData.email);
//     formDataToSend.append("bio", formData.bio);
//     if (file) {
//       formDataToSend.append("profilePic", file);
//     }

//     try {
//       // Replace with your API endpoint
//       await axios.put("https://api.example.com/profile", formDataToSend, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       alert("Profile updated successfully!");
//     } catch (err) {
//       setError("Failed to update profile. Please try again.");
//     } finally {
//       setIsSubmitting(false);
//     }
//   };

//   return (
//     <Container>
//       <Header_packages title="Profile Edit" />
//       <Header></Header>
//       <FormContainer>
//         <form onSubmit={handleSubmit}>
//           <AvatarWrapper>
//             {profilePic && <Avatar src={profilePic} alt="Profile Pic" />}
//             <UploadIcon htmlFor="profilePic">
//               <FaPlus />
//               <FileInput
//                 type="file"
//                 id="profilePic"
//                 name="profilePic"
//                 accept="image/*"
//                 onChange={handleFileChange}
//               />
//             </UploadIcon>
//           </AvatarWrapper>
//           <FormField>
//             <Label htmlFor="name">Name</Label>
//             <Input
//               type="text"
//               id="name"
//               name="name"
//               value={formData.name}
//               onChange={handleChange}
//               required
//             />
//           </FormField>
//           <FormField>
//             <Label htmlFor="email">Email</Label>
//             <Input
//               type="email"
//               id="email"
//               name="email"
//               value={formData.email}
//               onChange={handleChange}
//               required
//             />
//           </FormField>
//           <FormField>
//             <Label htmlFor="bio">Bio</Label>
//             <TextArea
//               id="bio"
//               name="bio"
//               value={formData.bio}
//               onChange={handleChange}
//               rows="4"
//             />
//           </FormField>
//           {error && <p style={{ color: "red" }}>{error}</p>}
//           <SubmitButton type="submit" disabled={isSubmitting}>
//             {isSubmitting ? "Updating..." : "Update Profile"}
//           </SubmitButton>
//         </form>
//       </FormContainer>
//     </Container>
//   );
// };

// export default ProfileEditPage;
