import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Card from "../components/Card";
import Historycard from "./Historycard";
import { useTranslation } from "react-i18next";

const Doctors_db = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const session = JSON.parse(sessionStorage.getItem("userData"));

  const showAlert = (text) => {
    Swal.fire({
      icon: "info",
      title: t("package_subscription"),
      text: t(text),
      showCancelButton: true,
      confirmButtonText: t("pay_now"),
      cancelButtonText: t("cancel"),
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        navigate(`/feature-package`);
      }
    });
  };

  const doctorsList = useCallback(() => {
    if (!session?.featuresPackage) {
      showAlert("no_subscription_text");
    } else {
      navigate(`/doctors-list`);
    }
  }, [navigate, session, t]);

  const vitalsClick = useCallback(() => {
    if (!session?.featuresPackage) {
      showAlert("no_subscription_text");
    } else {
      navigate(`/vitals`);
    }
  }, [navigate, session, t]);

  const prescriptionList = useCallback(() => {
    if (!session?.featuresPackage) {
      showAlert("no_subscription_text");
    } else {
      navigate(`/prescriptions-list`);
    }
  }, [navigate, session, t]);

  return (
    <div className="row mb-3">
      <div className="col-12" onClick={() => doctorsList()}>
        <Card
          image="assets/NewMiniapp/instant docs.png"
          title={t("call_a_doc")}
        />
      </div>
      <div className="col-6">
        <div className="d-flex flex-column mt-2">
          <div onClick={() => prescriptionList()}>
            <Historycard
              image="assets/NewMiniapp/medical_history.jpg"
              title={t("history")}
            />
          </div>
        </div>
      </div>
      <div className="col-6">
        <div className="d-flex flex-column mt-2">
          <div onClick={() => vitalsClick()}>
            <Historycard
              image="assets/NewMiniapp/medical-vitals.jpg"
              title={t("vitals")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Doctors_db;

// import React, { useState, useEffect, useCallback } from "react";
// import { useNavigate } from "react-router-dom";
// import Swal from "sweetalert2";
// import Card from "../components/Card";
// import Historycard from "./Historycard";
// import { useTranslation } from "react-i18next";
// const Doctors_db = () => {
//   const { t, i18n } = useTranslation();
//   const navigate = useNavigate();
//   const session = JSON.parse(sessionStorage.getItem("userData"));

//   // const doctorsList = useCallback(() => {
//   //   if (!session?.featuresPackage) {
//   //     Swal.fire({
//   //       icon: "info",
//   //       title: "Package Subscription",
//   //       text: "Sorry, you do not have an active subscription. Please subscribe to a features package to access.",
//   //       confirmButtonText: "Ok",
//   //       allowOutsideClick: false,
//   //     }).then((result) => {
//   //       if (result.isConfirmed) {
//   //         navigate(`/feature-package`);
//   //       }
//   //     });
//   //   } else {
//   //     navigate(`/doctors-list`);
//   //   }
//   // }, [navigate]);

//   const doctorsList = useCallback(() => {
//     if (!session?.featuresPackage) {
//       Swal.fire({
//         icon: "info",
//         title: "Package Subscription",
//         text: "You don't have an active subscription. Please subscribe to a feature package for just 5 AFN per day to access premium features.",
//         showCancelButton: true,
//         confirmButtonText: "Pay Now",
//         cancelButtonText: "Cancel",
//         allowOutsideClick: false,
//       }).then((result) => {
//         if (result.isConfirmed) {
//           navigate(`/feature-package`);
//         }
//       });
//     } else {
//       navigate(`/doctors-list`);
//     }
//   }, [navigate, session]);

//   const vitalsClick = useCallback(() => {
//     if (!session?.featuresPackage) {
//       Swal.fire({
//         icon: "info",
//         title: "Package Subscription",
//         text: "You don't have an active subscription. Please subscribe to a feature package for just 5 AFN per day to access premium features.",
//         showCancelButton: true,
//         confirmButtonText: "Pay Now",
//         cancelButtonText: "Cancel",
//         allowOutsideClick: false,
//       }).then((result) => {
//         if (result.isConfirmed) {
//           navigate(`/feature-package`);
//         }
//       });
//     } else {
//       navigate(`/vitals`);
//     }
//   }, [navigate]);

//   const prescriptionList = useCallback(() => {
//     if (!session?.featuresPackage) {
//       Swal.fire({
//         icon: "info",
//         title: "Package Subscription",
//         text: "You don't have an active subscription. Please subscribe to a feature package for just 5 AFN per day to access premium features.",
//         showCancelButton: true,
//         confirmButtonText: "Pay Now",
//         cancelButtonText: "Cancel",
//         allowOutsideClick: false,
//       }).then((result) => {
//         if (result.isConfirmed) {
//           navigate(`/feature-package`);
//         }
//       });
//     } else {
//       navigate(`/prescriptions-list`);
//     }
//   }, [navigate]);

//   return (
//     <div className="row mb-3">
//       <div className="col-12" onClick={() => doctorsList()}>
//         <Card image="assets/NewMiniapp/instant docs.png" title="Call a Doc" />
//       </div>
//       <div className="col-6">
//         <div className="d-flex flex-column mt-2">
//           <div onClick={() => prescriptionList()}>
//             <Historycard
//               image="assets/NewMiniapp/medical_history.jpg"
//               title="History"
//             />
//           </div>
//         </div>
//       </div>
//       <div className="col-6">
//         <div className="d-flex flex-column mt-2">
//           <div onClick={() => vitalsClick()}>
//             <Historycard
//               image="assets/NewMiniapp/medical-vitals.jpg"
//               title="Vitals"
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
// export default Doctors_db;
