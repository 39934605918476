// src/components/doctor-list-component.js
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { onValue, ref } from "firebase/database";
import { database } from "../firebase/firebaseConfig";
import Swal from "sweetalert2";
import { useLoading } from "../contexts/LoadingContext";
import { useTranslation } from "react-i18next";
import "./doctor-list-component.css";

const DoctorListComponent = () => {
  const [doctors, setDoctors] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [page, setPage] = useState(1);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const [checkCallDoc, setCallDocPck] = useState(false);
  const { isLoading, setIsLoading } = useLoading();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === "rtl";

  const session = JSON.parse(sessionStorage.getItem("userData"));

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(
          `https://bundleapi.webddocsystems.com/api/Account/DoctorList?lang=${t(
            "apiLanguage"
          )}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        if (data.responseCode === "0000") {
          if (Array.isArray(data.doctors)) {
            setDoctors(data.doctors);
          } else {
            console.log("No data found");
          }
        } else {
          console.error("API response error:", data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [page, setIsLoading]);

  // useEffect(() => {
  //   checkCallADocPackage();
  //   console.log(checkCallDoc)
  // }, [checkCallDoc]);

  useEffect(() => {
    const realtimeRef = ref(database, "Doctors");
    const unsubscribe = onValue(realtimeRef, (snapshot) => {
      const realtimeData = snapshot.val();
      if (realtimeData) {
        const updatedDoctors = doctors.map((doctor) => {
          const email = doctor.email.replace(/\./g, "");
          const status = realtimeData[email]?.status || doctor.onlineStatus;
          return { ...doctor, status };
        });
        setDoctors(updatedDoctors);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [doctors]);

  const checkCallADocPackage = useCallback(async () => {
    try {
      setIsLoading(true);
      const sessionUserId = session.applicationUserId;

      const response = await fetch(
        `https://bundleapi.webddocsystems.com/api/Account/CheckPackage?patientid=${sessionUserId}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      if (data.responseCode === "0000") {
        setCallDocPck(true);
        console.log("Package found");
        return true;
      } else {
        setCallDocPck(false);
        console.log("No package found");
        return false;
      }
    } catch (error) {
      console.error("Error checking package:", error);
      return false;
    } finally {
      setIsLoading(false);
    }
  }, [session]);

  const onAudioVideoCallClick = useCallback(
    async (id, email) => {
      const hasPackage = await checkCallADocPackage();
      if (!hasPackage) {
        navigate("/paymentscreen");
      } else {
        if (email) {
          // alert('video')
          navigate(`/video-call`, { state: { email } });
        } else {
          console.error("Doctor profile data is not available.");
        }
      }
    },
    [navigate, checkCallADocPackage]
  );

  const onDoctorProfile = useCallback(
    (id, email, status) => {
      sessionStorage.setItem("doctorEmail", email);
      sessionStorage.setItem("doctorStatus", status);
      navigate(`/doctor-profile/${id}`);
    },
    [navigate]
  );

  const handleSearchInputChange = useCallback((event) => {
    setSearchKeyword(event.target.value);
  }, []);

  const filteredDoctors = doctors.filter(
    (doctor) =>
      doctor.name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      doctor.specialty.toLowerCase().includes(searchKeyword.toLowerCase())
  );

  const countLanguages = (doctor) => {
    return doctor.languages ? doctor.languages.split(",").length : 0;
  };

  // Sort doctors first by status and then by language count
  const sortDoctors = (doctors) => {
    return doctors.sort((a, b) => {
      // Status order
      const statusOrder = {
        online: 1,
        busy: 2,
        offline: 3,
      };

      // Compare statuses first
      const statusComparison = statusOrder[a.status] - statusOrder[b.status];
      if (statusComparison !== 0) {
        return statusComparison; 
      }

      return countLanguages(b) - countLanguages(a);
    });
  };


  // Sort filtered doctors
  const sortedDoctors = sortDoctors(filteredDoctors);

  // Render doctors
  const doctorListToRender = sortedDoctors;

  // const doctorKarim = filteredDoctors.find(
  //   (doctor) =>
  //     doctor.applicationUserId === "6ae2bfbb-1c12-4b4b-9739-78e6df127fb8"
  // );

  // const onlineDoctors = filteredDoctors.filter(
  //   (doctor) => doctor.status === "online" && doctor !== doctorKarim
  // );
  // const busyDoctors = filteredDoctors.filter(
  //   (doctor) => doctor.status === "busy" && doctor !== doctorKarim
  // );
  // const offlineDoctors = filteredDoctors.filter(
  //   (doctor) => doctor.status === "offline" && doctor !== doctorKarim
  // );

  // const doctorListToRender = doctorKarim
  //   ? [doctorKarim].concat(onlineDoctors, busyDoctors, offlineDoctors)
  //   : onlineDoctors.concat(busyDoctors, offlineDoctors);

  // const onlineDoctors = filteredDoctors.filter(
  //   (doctor) => doctor.status === "online"
  // );
  // const busyDoctors = filteredDoctors.filter(
  //   (doctor) => doctor.status === "busy"
  // );
  // const offlineDoctors = filteredDoctors.filter(
  //   (doctor) => doctor.status === "offline"
  // );
  // const doctorKarim = filteredDoctors.filter(
  //   (doctor) =>
  //     doctor.applicationUserId === "6ae2bfbb-1c12-4b4b-9739-78e6df127fb8"
  // );

  // const doctorListToRender = onlineDoctors.concat(busyDoctors, offlineDoctors);

  return (
    <div style={{ marginBottom: "100px", direction: isRTL ? "rtl" : "ltr" }}>
      <div className="mt-4">
        <input
          type="text"
          placeholder={t("searchPlaceholder")}
          className={`search-bar ${isRTL ? "rtl-input" : ""}`}
          value={searchKeyword}
          onChange={handleSearchInputChange}
        />
      </div>

      <Row
        xs={1}
        md={2}
        lg={2}
        className={`gx-3 mt-3 ${isRTL ? "rtl-row" : ""}`}
      >
        {doctorListToRender.map((doctor) => (
          <Col key={doctor.applicationUserId} lg={4} md={6} className="mb-3">
            <Card className={`h-100 ${isRTL ? "rtl-card" : ""}`}>
              <Card.Body>
                <Row>
                  <Col
                    xs={3}
                    className={`d-flex flex-column justify-content-center align-items-center ${
                      isRTL ? "rtl-col" : ""
                    }`}
                  >
                    <div className="ellipse2">
                      <div>
                        <img
                          className="d2-parent2"
                          alt=""
                          src={doctor.imgLink}
                        />
                        <div
                          className={`${
                            doctor.status === "online"
                              ? "star-field"
                              : doctor.status === "offline"
                              ? "offline-star-field"
                              : "busy-star-field"
                          }`}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className={` ${isRTL ? "rtl-text" : ""}`}>
                      <div className="mb-2">
                        <span
                          style={{
                            fontWeight: "600",
                            height: "20px",
                          }}
                        >
                          {doctor.name}
                        </span>
                        <br />
                        <span className="text-muted">{doctor.specialty}</span>
                        <br />
                        {/* <div>
                        <span className="">{doctor.languages}</span>
                        </div> */}
                        <span>{doctor.gender}</span>{" "}
                        <span className="text-info">
                          ({doctor.doctorDutyTime})
                        </span>
                        <div>
                          {doctor.languages
                            .split(",")
                            .map((language, index) => (
                              <span
                                key={index}
                                className="badge me-2"
                                style={{
                                  fontSize: "12px",
                                  backgroundColor: "rgb(190, 211, 8)",
                                }}
                              >
                                {language}
                              </span>
                            ))}
                        </div>
                        <div className="mb-1">
                          {[...Array(Math.round(doctor.averageRating))].map(
                            (_, index) => (
                              <img
                                key={index}
                                src="/assets/star-component.svg"
                                alt="Star rating"
                                className="me-1"
                                style={{ width: "15px", height: "15px" }}
                              />
                            )
                          )}
                        </div>
                        <div
                          className={`badge text-light ${
                            doctor.status === "online"
                              ? "bg-success"
                              : doctor.status === "offline"
                              ? "bg-secondary"
                              : "bg-warning"
                          }`}
                        >
                          {doctor.status === "online"
                            ? t("online")
                            : doctor.status === "offline"
                            ? t("offline")
                            : t("busy")}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col
                    xs={3}
                    className={`d-flex flex-column justify-content-center align-items-center ${
                      isRTL ? "rtl-col" : ""
                    }`}
                  >
                    <Button
                      style={{
                        background: "rgb(113, 158, 25)",
                        color: "white",
                        border: "none",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }}
                      variant="outline-primary"
                      className={`mb-2 custom-button ${
                        isRTL ? "rtl-button" : ""
                      }`}
                      onClick={() =>
                        onDoctorProfile(
                          doctor.applicationUserId,
                          doctor.email,
                          doctor.status
                        )
                      }
                    >
                      {t("profileButton")}
                    </Button>
                    <Button
                      style={{
                        background: "rgb(113, 158, 25)",
                        color: "white",
                        border: "none",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }}
                      className={`mb-2 custom-button ${
                        isRTL ? "rtl-button" : ""
                      }`}
                      variant="outline-success"
                      onClick={() => {
                        if (doctor.status !== "online") {
                          Swal.fire({
                            icon: "info",
                            // title: "Please wait",
                            text: t("pleaseWait"),
                            confirmButtonText: "OK",
                            allowOutsideClick: false,
                          }).then(() => {
                            navigate(`/doctors-list`);
                          });
                        } else {
                          onAudioVideoCallClick(
                            doctor.applicationUserId,
                            doctor.email
                          );
                        }
                      }}
                    >
                      {t("videoCallButton")}
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default DoctorListComponent;

// import React, { useState, useEffect, useCallback } from "react";
// import { useNavigate } from "react-router-dom";
// import { Container, Row, Col, Card, Button } from "react-bootstrap";
// import { onValue, ref } from "firebase/database";
// import { database } from "../firebase/firebaseConfig";
// import Swal from "sweetalert2";
// import { useLoading } from "../contexts/LoadingContext";
// import "./doctor-list-component.css";

// const DoctorListComponent = () => {
//   const [doctors, setDoctors] = useState([]);
//   const [searchKeyword, setSearchKeyword] = useState("");
//   const [page, setPage] = useState(1);
//   const [allDataLoaded, setAllDataLoaded] = useState(false);
//   const { isLoading, setIsLoading } = useLoading();
//   const navigate = useNavigate();

//   const session = JSON.parse(sessionStorage.getItem("userData"));

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         setIsLoading(true);
//         const response = await fetch(
//           `https://bundleapi.webddocsystems.com/api/Account/DoctorList`
//         );
//         if (!response.ok) {
//           throw new Error("Failed to fetch data");
//         }
//         const data = await response.json();
//         if (data.responseCode === "0000") {
//           if (Array.isArray(data.doctors)) {
//             setDoctors(data.doctors);
//           } else {
//             console.log("No data found");
//           }
//         } else {
//           console.error("API response error:", data.message);
//         }
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchData();
//   }, [page, setIsLoading]);

//   useEffect(() => {
//     const realtimeRef = ref(database, "Doctors");
//     const unsubscribe = onValue(realtimeRef, (snapshot) => {
//       const realtimeData = snapshot.val();
//       if (realtimeData) {
//         const updatedDoctors = doctors.map((doctor) => {
//           const email = doctor.email.replace(/\./g, "");
//           const status = realtimeData[email]?.status || doctor.onlineStatus;
//           return { ...doctor, status };
//         });
//         setDoctors(updatedDoctors);
//       }
//     });

//     return () => {
//       unsubscribe();
//     };
//   }, [doctors]);

//   const onAudioVideoCallClick = useCallback(
//     (id, email) => {
//       if (!session?.package) {
//         navigate("/paymentscreen");
//       } else {
//         if (email) {
//           navigate(`/video-call`, { state: { email } });
//         } else {
//           console.error("Doctor profile data is not available.");
//         }
//       }
//     },
//     [navigate, session]
//   );

//   const onDoctorProfile = useCallback(
//     (id, email, status) => {
//       sessionStorage.setItem("doctorEmail", email);
//       sessionStorage.setItem("doctorStatus", status);
//       navigate(`/doctor-profile/${id}`);
//     },
//     [navigate]
//   );

//   const handleSearchInputChange = useCallback((event) => {
//     setSearchKeyword(event.target.value);
//   }, []);

//   const filteredDoctors = doctors.filter(
//     (doctor) =>
//       doctor.name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
//       doctor.specialty.toLowerCase().includes(searchKeyword.toLowerCase())
//   );

//   const onlineDoctors = filteredDoctors.filter(
//     (doctor) => doctor.status === "online"
//   );
//   const busyDoctors = filteredDoctors.filter(
//     (doctor) => doctor.status === "busy"
//   );
//   const offlineDoctors = filteredDoctors.filter(
//     (doctor) => doctor.status === "offline"
//   );

//   const doctorListToRender = onlineDoctors.concat(busyDoctors, offlineDoctors);

//   return (
//     <div style={{ marginBottom: "100px" }}>
//       <div className="mt-4">
//         <input
//           type="text"
//           placeholder="Search.."
//           className="search-bar"
//           value={searchKeyword}
//           onChange={handleSearchInputChange}
//         />
//       </div>

//       <Row xs={1} md={2} lg={2} className="gx-3 mt-3">
//         {doctorListToRender.map((doctor) => (
//           <Col key={doctor.applicationUserId} lg={4} md={6} className="mb-3">
//             <Card className="h-100">
//               <Card.Body>
//                 <Row>
//                   <Col
//                     xs={3}
//                     className="d-flex align-items-center justify-content-center"
//                   >
//                     <div className="ellipse2">
//                       <div>
//                         <img
//                           className="d2-parent2"
//                           alt=""
//                           src={doctor.imgLink}
//                         />
//                         <div
//                           className={`${
//                             doctor.status === "online"
//                               ? "star-field"
//                               : doctor.status === "offline"
//                               ? "offline-star-field"
//                               : "busy-star-field"
//                           }`}
//                         />
//                       </div>
//                     </div>
//                   </Col>
//                   <Col xs={6}>
//                     <div className="d-flex flex-column justify-content-center h-100">
//                       <div className="mb-2">
//                         <span
//                           style={{
//                             fontWeight: "600",
//                             height: "20px",
//                           }}
//                         >
//                           {doctor.name}
//                         </span>
//                         <br></br>
//                         <span className="text-muted">{doctor.specialty}</span>
//                         <br></br>
//                         <span>{doctor.gender}</span>{" "}
//                         <span className="text-info">
//                           ({doctor.doctorDutyTime})
//                         </span>
//                         <div className="mb-2">
//                           {[...Array(Math.round(doctor.averageRating))].map(
//                             (_, index) => (
//                               <img
//                                 key={index}
//                                 src="/star-component.svg"
//                                 alt="Star rating"
//                                 className="me-1"
//                                 style={{ width: "15px", height: "15px" }}
//                               />
//                             )
//                           )}
//                         </div>
//                         <div
//                           className={`badge text-light ${
//                             doctor.status === "online"
//                               ? "bg-success"
//                               : doctor.status === "offline"
//                               ? "bg-secondary"
//                               : "bg-warning"
//                           }`}
//                         >
//                           {doctor.status.toUpperCase()}
//                         </div>
//                       </div>
//                     </div>
//                   </Col>
//                   <Col
//                     xs={3}
//                     className="d-flex flex-column justify-content-center"
//                   >
//                     <Button
//                       style={{
//                         background: "rgb(113, 158, 25)",
//                         color: "white",
//                         border: "none",
//                         borderRadius: "10px",
//                         cursor: "pointer",
//                       }}
//                       variant="outline-primary"
//                       className="mb-2 custom-button"
//                       onClick={() =>
//                         onDoctorProfile(
//                           doctor.applicationUserId,
//                           doctor.email,
//                           doctor.status
//                         )
//                       }
//                     >
//                       Profile
//                     </Button>
//                     <Button
//                       style={{
//                         background: "rgb(113, 158, 25)",
//                         color: "white",
//                         border: "none",
//                         borderRadius: "10px",
//                         cursor: "pointer",
//                       }}
//                       className="mb-2 custom-button"
//                       variant="outline-success"
//                       onClick={() => {
//                         if (doctor.status !== "online") {
//                           Swal.fire({
//                             icon: "info",
//                             title: "Please wait",
//                             text: "Please wait for the doctor to come online.",
//                             confirmButtonText: "OK",
//                             allowOutsideClick: false,
//                           }).then(() => {
//                             navigate(`/doctors-list`);
//                           });
//                         } else {
//                           onAudioVideoCallClick(
//                             doctor.applicationUserId,
//                             doctor.email
//                           );
//                         }
//                       }}
//                     >
//                       Video
//                     </Button>
//                   </Col>
//                 </Row>
//               </Card.Body>
//             </Card>
//           </Col>
//         ))}
//       </Row>
//     </div>
//   );
// };

// export default DoctorListComponent;
