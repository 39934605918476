// src/screens/DoctorProfile.js
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FrameComponent1 from "../components/doctore-experience-frame";
import Header_packages from "../components/Header_packages";
import axios from "axios";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useLoading } from "../contexts/LoadingContext";
import { useTranslation } from "react-i18next";
import "./doctor-profile.css";

const DoctorProfile = () => {
  const [doctorProfile, setDoctorProfile] = useState(null);
  const navigate = useNavigate();
  const { isLoading, setIsLoading } = useLoading();
  const { id } = useParams();
  const session = JSON.parse(sessionStorage.getItem("userData"));
  const { t } = useTranslation(); // Translation hook

  useEffect(() => {
    const fetchDoctorProfile = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(
          "https://bundleapi.webddocsystems.com/api/Account/DoctorProfile",
          null,
          {
            params: {
              doctorid: id,
              lang: t("apiLanguage"),
            },
          }
        );

        if (response.status === 200 && response.data.responseCode === "0000") {
          setDoctorProfile(response.data.profile);
        } else {
          throw new Error("Invalid response format");
        }
      } catch (error) {
        console.error("Error fetching doctor profile:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDoctorProfile();
  }, [id, setIsLoading]);

  const videoCallClick = useCallback(
    (email) => {
      if (!session?.package) {
        navigate("/paymentscreen");
      } else {
        if (email) {
          navigate(`/video-call`, { state: { email } });
        } else {
          console.error("Doctor profile data is not available.");
        }
      }
    },
    [navigate, session]
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Container fluid>
        <Header_packages title={t("doctorProfileTitle")} />
        <div style={{ paddingTop: "50px" }}>
          <Row style={{ marginBottom: "100px" }}>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div>
                {doctorProfile && (
                  <>
                    <Col xs={12} sm={12} md={4} lg={4} className="mt-5">
                      <div style={{ textAlign: "center" }}>
                        <img
                          src={doctorProfile.imgLink}
                          style={{
                            width: "180px",
                            height: "180px",
                            borderRadius: "50%",
                            "@media (maxWidth: 767px)": {
                              width: "60%",
                            },
                          }}
                        />
                        <div className="mt-4">
                          <p className="doctor-profile-name">
                            {doctorProfile.name}
                          </p>
                          <div className="row align-items-center">
                            <div className="col d-flex justify-content-center">
                              {/* <img
                                style={{
                                  marginRight: "10px",
                                  marginBottom: "15px",
                                }}
                                loading="eager"
                                alt=""
                                src="/star-81.svg"
                              /> */}
                              <p className="custom-ratings">
                                {doctorProfile.averageRating}{" "}
                                {t("positivePatientReviews")}
                              </p>
                            </div>
                          </div>
                        </div>

                        <FrameComponent1 data={doctorProfile} />
                      </div>
                    </Col>

                    <Col xs={12} sm={12} md={8} lg={8} className="mt-4 p-2">
                      <p className="doctor-headings">{t("about")}</p>
                      <p
                        className="custom-text-class"
                        style={{ textAlign: "justify" }}
                      >
                        {doctorProfile.detailedInformation}
                      </p>
                      <p className="doctor-headings">{t("experience")}</p>
                      <p
                        className="custom-text-class"
                        style={{ textAlign: "justify" }}
                      >
                        {doctorProfile.name} has over {doctorProfile.experience}{" "}
                        years of experience in their field.
                      </p>
                      <p className="doctor-headings">{t("qualification")}</p>
                      <p className="custom-text-class">
                        {doctorProfile.allqualifications}.
                      </p>
                    </Col>
                  </>
                )}
                <div className="d-flex justify-content-center">
                  <Button
                    variant="outline-info"
                    style={{
                      fontWeight: "600",
                      height: "50px",
                      backgroundColor: "rgb(113, 158, 25)",
                      color: "#ffffff",
                      minWidth: "40%",
                      borderRadius: "40px",
                      border: 0,
                      boxShadow: "0 0 6.3px 1px rgba(0, 0, 0, 0.25)",
                    }}
                    type="button"
                    onClick={() => videoCallClick(doctorProfile.email)}
                  >
                    {t("videoConsultation")}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default DoctorProfile;

// import React, { useState, useEffect, useCallback } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import FrameComponent1 from "../components/doctore-experience-frame";
// import Header_packages from "../components/Header_packages";
// import axios from "axios";
// import { Container, Row, Col, Button } from "react-bootstrap";
// import { useLoading } from "../contexts/LoadingContext";
// import "./doctor-profile.css";

// const DoctorProfile = () => {
//   const [doctorProfile, setDoctorProfile] = useState(null);
//   const navigate = useNavigate();
//   const { isLoading, setIsLoading } = useLoading();
//   const { id } = useParams();
//   const session = JSON.parse(sessionStorage.getItem("userData"));
//   useEffect(() => {
//     const fetchDoctorProfile = async () => {
//       setIsLoading(true);
//       try {
//         const response = await axios.post(
//           "https://bundleapi.webddocsystems.com/api/Account/DoctorProfile",
//           null,
//           {
//             params: {
//               doctorid: id,
//             },
//           }
//         );

//         if (response.status === 200 && response.data.responseCode === "0000") {
//           setDoctorProfile(response.data.profile);
//         } else {
//           throw new Error("Invalid response format");
//         }
//       } catch (error) {
//         console.error("Error fetching doctor profile:", error);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchDoctorProfile();
//   }, [id, setIsLoading]);

//   // const videoCallClick = useCallback(
//   //   (email) => {
//   //     navigate(`/video-call`, { state: { email } });
//   //   },
//   //   [navigate]
//   // );

//   const videoCallClick = useCallback(
//     (email) => {
//       if (!session?.package) {
//         navigate("/paymentscreen");
//       } else {
//         if (email) {
//           navigate(`/video-call`, { state: { email } });
//         } else {
//           console.error("Doctor profile data is not available.");
//         }
//       }
//     },
//     [navigate, session]
//   );

//   if (isLoading) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <>
//       <Container fluid>
//         <Header_packages title="Doctor Profile" />
//         <div style={{ paddingTop: "50px" }}>
//           <Row style={{ marginBottom: "100px" }}>
//             <Col xs={12} sm={12} md={12} lg={12}>
//               <div>
//                 {doctorProfile && (
//                   <>
//                     <Col xs={12} sm={12} md={4} lg={4} className="mt-5">
//                       <div style={{ textAlign: "center" }}>
//                         <img
//                           src={doctorProfile.imgLink}
//                           style={{
//                             width: "180px",
//                             height: "180px",
//                             borderRadius: "50%",
//                             "@media (maxWidth: 767px)": {
//                               width: "60%",
//                             },
//                           }}
//                         />
//                         <div className="mt-4">
//                           <p className="doctor-profile-name">
//                             {doctorProfile.name}
//                           </p>
//                           <div className="row align-items-center">
//                             <div className="col d-flex justify-content-center">
//                               <img
//                                 style={{
//                                   marginRight: "10px",
//                                   marginBottom: "15px",
//                                 }}
//                                 loading="eager"
//                                 alt=""
//                                 src="/star-81.svg"
//                               />
//                               <p className="custom-ratings">
//                                 {doctorProfile.averageRating} Positive Patients
//                                 Reviews
//                               </p>
//                             </div>
//                           </div>
//                         </div>

//                         <FrameComponent1 data={doctorProfile} />
//                       </div>
//                     </Col>

//                     <Col xs={12} sm={12} md={8} lg={8} className="mt-4 p-2">
//                       <p className="doctor-headings">About</p>
//                       <p
//                         className="custom-text-class"
//                         style={{ textAlign: "justify" }}
//                       >
//                         {doctorProfile.detailedInformation}
//                       </p>
//                       <p className="doctor-headings">Experience</p>
//                       <p
//                         className="custom-text-class"
//                         style={{ textAlign: "justify" }}
//                       >
//                         {doctorProfile.name} has over {doctorProfile.experience}{" "}
//                         years of experience in their field.
//                       </p>
//                       <p className="doctor-headings">Qualification</p>
//                       <p className="custom-text-class">
//                         {doctorProfile.allqualifications}.
//                       </p>
//                     </Col>
//                   </>
//                 )}
//                 <div className="d-flex justify-content-center">
//                   <Button
//                     variant="outline-info"
//                     style={{
//                       fontWeight: "600",
//                       height: "50px",
//                       backgroundColor: 'rgb(113, 158, 25)',
//                       color: "#ffffff",
//                       minWidth: "40%",
//                       borderRadius: "40px",
//                       border: 0,
//                       boxShadow: "0 0 6.3px 1px rgba(0, 0, 0, 0.25)",
//                     }}
//                     type="button"
//                     onClick={() => videoCallClick(doctorProfile.email)}
//                   >
//                     Video Consultation
//                   </Button>
//                 </div>
//               </div>
//             </Col>
//           </Row>
//         </div>
//       </Container>
//     </>
//   );
// };

// export default DoctorProfile;
