import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import "../i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: url("/assets/NewMiniapp/main-doctor5.jpg") no-repeat center center
    fixed;
  background-size: cover;
  font-family: "Roboto", sans-serif;
`;

const Card = styled.div`
  width: 90%;
  max-width: 500px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.15);
  padding: 30px;
  text-align: center;
  position: relative;
  overflow: hidden;
`;

const Header = styled.h1`
  margin-bottom: 20px;
  color: #333;
`;

const FormField = styled.div`
  margin-bottom: 20px;
  text-align: left;
`;

const Label = styled.label`
  display: block;
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-sizing: border-box;
  outline: none;
  transition: border 0.3s ease;
  &:focus {
    border-color: rgb(113, 158, 25);
  }
`;

const ErrorText = styled.div`
  color: red;
  font-size: 14px;
`;

const SubmitButton = styled.button`
  background: rgb(113, 158, 25);
  color: white;
  border: none;
  border-radius: 30px;
  padding: 12px 25px;
  font-size: 18px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: background 0.3s ease, transform 0.3s ease;
  &:hover {
    background: ${(props) =>
      props.disabled ? "rgb(113, 158, 25)" : "#0056b3"};
    transform: ${(props) => (props.disabled ? "none" : "scale(1.05)")};
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
`;

const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  background: #f5f5f5;
  border-radius: 10px;
  border: 1px solid #ddd;
  font-size: 16px;
  color: #333;
  font-weight: bold;
  > svg {
    margin-right: 10px;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
`;

const DropdownItem = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background: #f0f0f0;
  }
`;

const DisclaimerModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 20px;
  width: 80%;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 325px;
`;

const ModalHeader = styled.h2`
  margin-bottom: 20px;
  color: #333;
`;

const ModalBody = styled.div`
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
`;

const VerifyingText = styled.div`
  font-size: 16px;
  color: #333;
  margin-top: 20px;
`;

const SignUpForm = () => {
  const { t, i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    setDropdownOpen(false);
  };

  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("93");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleMobileNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    if (value.length > 11) return;

    setMobileNumber(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!name) newErrors.name = t("nameError");
    if (!mobileNumber || mobileNumber.length !== 11) {
      newErrors.mobileNumber = t("mobileNumberError");
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Show the disclaimer modal and start verifying
    // setIsModalOpen(true);
  };

  const handleVerfification = async () => {
    setIsVerifying(true);
    try {
      const response = await authenticateUser(mobileNumber, name);
      if (response.data.patientProfile.isFeaturePackageActivated) {
        navigate("/dashboard");
      } else {
        navigate("/feature-package");
      }
    } catch (error) {
      console.error("Error during authentication:", error);
    } finally {
      setIsVerifying(false);
    }
  };

  const authenticateUser = async (userMsisdn, userPName) => {
    try {
      const response = await axios.post(
        "https://bundleapi.webddocsystems.com/api/Account/Register",
        {
          mobileNumber: userMsisdn,
          customerName: userPName,
          gender: "male",
        }
      );

      const user = {
        userMsisdn: response.data.patientProfile.mobileNumber,
        userName: response.data.patientProfile.name,
        applicationUserId: response.data.patientProfile.id,
        userEmail: `${response.data.patientProfile.mobileNumber}@webdoc.com.pk`,
        package: response.data.patientProfile.isPackageActivated,
        featuresPackage: response.data.patientProfile.isFeaturePackageActivated,
        headOfGreeting: response.data.patientProfile.headOfGreeting,
        contentOfGreeting: response.data.patientProfile.contentOfGreeting,
        appointmentId: response.data.patientProfile.appointmentid,
        featurePackageDetails:
          response.data.patientProfile.featurePackageDetails,
        message: "User registered and authenticated successfully.",
      };
      sessionStorage.setItem("userData", JSON.stringify(user));
      return response;
    } catch (error) {
      console.error("Error registering user:", error);
      throw error;
    }
  };

  return (
    <Container>
      <Card>
        <Header>{t("welcome")}</Header>
        <DropdownContainer>
          <DropdownHeader onClick={() => setDropdownOpen(!dropdownOpen)}>
            <FontAwesomeIcon icon={faGlobe} />
            {t("selectedLanguage")}
          </DropdownHeader>
          {dropdownOpen && (
            <DropdownMenu>
              <DropdownItem onClick={() => handleLanguageChange("en")}>
                English
              </DropdownItem>
              <DropdownItem onClick={() => handleLanguageChange("ps")}>
                پشتو
              </DropdownItem>
              <DropdownItem onClick={() => handleLanguageChange("fa")}>
                دری
              </DropdownItem>
            </DropdownMenu>
          )}
        </DropdownContainer>
        <form onSubmit={handleSubmit}>
          <FormField>
            <Label htmlFor="name">{t("nameLabel")}</Label>
            <Input
              type="text"
              id="name"
              placeholder={t("namePlaceholder")}
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{ borderColor: errors.name ? "red" : "#ddd" }}
            />
            {errors.name && <ErrorText>{errors.name}</ErrorText>}
          </FormField>

          <FormField>
            <Label htmlFor="mobileNumber">{t("mobileNumberLabel")}</Label>
            <Input
              type="text"
              id="mobileNumber"
              placeholder={t("mobileNumberPlaceholder")}
              value={mobileNumber}
              onChange={handleMobileNumberChange}
              style={{ borderColor: errors.mobileNumber ? "red" : "#ddd" }}
            />
            {errors.mobileNumber && (
              <ErrorText>{errors.mobileNumber}</ErrorText>
            )}
          </FormField>

          <SubmitButton
            onClick={handleVerfification}
            type="submit"
            disabled={isVerifying}
          >
            {isVerifying ? t("verifyingText") : t("nextButton")}
          </SubmitButton>
        </form>
        <div className="mt-2 p-1" style={{ backgroundColor: "rgb(236 203 203)", borderRadius: "10px", fontSize: "12px"}}>
          <p>
            <span className="fw-bold text-danger">{t("disclaimerTitle")}{":  "}</span>
            <span className="">{t("disclaimerText")}</span>
          </p>
        </div>  
      </Card>

      {/* <DisclaimerModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        ariaHideApp={false}
        contentLabel={t("disclaimerTitle")}
      >
        <ModalHeader>{t("disclaimerTitle")}</ModalHeader>
        <ModalBody>{t("disclaimerText")}</ModalBody>
        <SubmitButton onClick={handleCloseModal} disabled={isVerifying}>
          {t("okButton")}
        </SubmitButton>
        {isVerifying && <VerifyingText>{t("verifyingText")}</VerifyingText>}
      </DisclaimerModal> */}
    </Container>
  );
};

export default SignUpForm;

// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import styled from "styled-components";
// import { useTranslation } from "react-i18next";
// import "../i18n";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faGlobe } from "@fortawesome/free-solid-svg-icons";
// import Modal from "react-modal";

// const Container = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   min-height: 100vh;
//   background: url("/assets/NewMiniapp/main-doctor5.jpg") no-repeat center center
//     fixed;
//   background-size: cover;
//   font-family: "Roboto", sans-serif;
// `;

// const Card = styled.div`
//   width: 90%;
//   max-width: 500px;
//   background: rgba(255, 255, 255, 0.9);
//   border-radius: 20px;
//   box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.15);
//   padding: 30px;
//   text-align: center;
//   position: relative;
//   overflow: hidden;
// `;

// const Header = styled.h1`
//   margin-bottom: 20px;
//   color: #333;
// `;

// const FormField = styled.div`
//   margin-bottom: 20px;
//   text-align: left;
// `;

// const Label = styled.label`
//   display: block;
//   font-size: 16px;
//   color: #333;
//   margin-bottom: 5px;
// `;

// const Input = styled.input`
//   width: 100%;
//   padding: 10px;
//   font-size: 16px;
//   border: 1px solid #ddd;
//   border-radius: 10px;
//   box-sizing: border-box;
//   outline: none;
//   transition: border 0.3s ease;
//   &:focus {
//     border-color: rgb(113, 158, 25);
//   }
// `;

// const ErrorText = styled.div`
//   color: red;
//   font-size: 14px;
// `;

// const SubmitButton = styled.button`
//   background: rgb(113, 158, 25);
//   color: white;
//   border: none;
//   border-radius: 30px;
//   padding: 12px 25px;
//   font-size: 18px;
//   cursor: pointer;
//   transition: background 0.3s ease, transform 0.3s ease;
//   &:hover {
//     background: #0056b3;
//     transform: scale(1.05);
//   }
// `;

// const DropdownContainer = styled.div`
//   position: relative;
//   display: inline-block;
//   margin-bottom: 20px;
// `;

// const DropdownHeader = styled.div`
//   display: flex;
//   align-items: center;
//   cursor: pointer;
//   padding: 10px;
//   background: #f5f5f5;
//   border-radius: 10px;
//   border: 1px solid #ddd;
//   font-size: 16px;
//   color: #333;
//   font-weight: bold;
//   > svg {
//     margin-right: 10px;
//   }
// `;

// const DropdownMenu = styled.div`
//   position: absolute;
//   top: 100%;
//   left: 0;
//   width: 100%;
//   background: #fff;
//   border: 1px solid #ddd;
//   border-radius: 10px;
//   box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
//   z-index: 1000;
// `;

// const DropdownItem = styled.div`
//   padding: 10px;
//   cursor: pointer;
//   &:hover {
//     background: #f0f0f0;
//   }
// `;

// const DisclaimerModal = styled(Modal)`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   background: rgba(0, 0, 0, 0.5);
//   border-radius: 10px;
//   padding: 20px;
//   width: 80%;
//   max-width: 600px;
//   margin: 0 auto;
// `;

// const ModalHeader = styled.h2`
//   margin-bottom: 20px;
//   color: #333;
// `;

// const ModalBody = styled.div`
//   font-size: 16px;
//   color: #333;
//   margin-bottom: 20px;
// `;

// const SignUpForm = () => {
//   const { t, i18n } = useTranslation();
//   const [dropdownOpen, setDropdownOpen] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const handleLanguageChange = (language) => {
//     i18n.changeLanguage(language);
//     setDropdownOpen(false);
//   };

//   const [name, setName] = useState("");
//   const [mobileNumber, setMobileNumber] = useState("93");
//   const [errors, setErrors] = useState({});
//   const navigate = useNavigate();

//   const handleMobileNumberChange = (e) => {
//     const value = e.target.value.replace(/\D/g, "");
//     if (value.length > 11) return;

//     setMobileNumber(value);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     const newErrors = {};
//     if (!name) newErrors.name = t("nameError");
//     if (!mobileNumber || mobileNumber.length !== 11) {
//       newErrors.mobileNumber = t("mobileNumberError");
//     }

//     if (Object.keys(newErrors).length > 0) {
//       setErrors(newErrors);
//       return;
//     }

//     // Show the disclaimer modal
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = async () => {
//     setIsModalOpen(false);

//     try {
//       const response = await authenticateUser(mobileNumber, name);
//       if (response.data.patientProfile.isFeaturePackageActivated) {
//         navigate("/dashboard");
//       } else {
//         navigate("/feature-package");
//       }
//     } catch (error) {
//       console.error("Error during authentication:", error);
//     }
//   };

//   const authenticateUser = async (userMsisdn, userPName) => {
//     try {
//       const response = await axios.post(
//         "https://bundleapi.webddocsystems.com/api/Account/Register",
//         {
//           mobileNumber: userMsisdn,
//           customerName: userPName,
//           gender: "male",
//         }
//       );

//       const user = {
//         userMsisdn: response.data.patientProfile.mobileNumber,
//         userName: response.data.patientProfile.name,
//         applicationUserId: response.data.patientProfile.id,
//         userEmail: `${response.data.patientProfile.mobileNumber}@webdoc.com.pk`,
//         package: response.data.patientProfile.isPackageActivated,
//         featuresPackage: response.data.patientProfile.isFeaturePackageActivated,
//         headOfGreeting: response.data.patientProfile.headOfGreeting,
//         contentOfGreeting: response.data.patientProfile.contentOfGreeting,
// appointmentId: response.data.patientProfile.appointmentid,
//         featurePackageDetails:
//           response.data.patientProfile.featurePackageDetails,
//         message: "User registered and authenticated successfully.",
//       };
//       sessionStorage.setItem("userData", JSON.stringify(user));
//       return response;
//     } catch (error) {
//       console.error("Error registering user:", error);
//       throw error;
//     }
//   };

//   return (
//     <Container>
//       <Card>
//         <Header>{t("welcome")}</Header>
//         <DropdownContainer>
//           <DropdownHeader onClick={() => setDropdownOpen(!dropdownOpen)}>
//             <FontAwesomeIcon icon={faGlobe} />
//             {t("language")}
//           </DropdownHeader>
//           {dropdownOpen && (
//             <DropdownMenu>
//               <DropdownItem onClick={() => handleLanguageChange("en")}>
//                 English
//               </DropdownItem>
//               <DropdownItem onClick={() => handleLanguageChange("ps")}>
//                 پشتو
//               </DropdownItem>
//               <DropdownItem onClick={() => handleLanguageChange("fa")}>
//                 دری
//               </DropdownItem>
//             </DropdownMenu>
//           )}
//         </DropdownContainer>
//         <form onSubmit={handleSubmit}>
//           <FormField>
//             <Label htmlFor="name">{t("nameLabel")}</Label>
//             <Input
//               type="text"
//               id="name"
//               placeholder={t("namePlaceholder")}
//               value={name}
//               onChange={(e) => setName(e.target.value)}
//               style={{ borderColor: errors.name ? "red" : "#ddd" }}
//             />
//             {errors.name && <ErrorText>{errors.name}</ErrorText>}
//           </FormField>

//           <FormField>
//             <Label htmlFor="mobileNumber">{t("mobileNumberLabel")}</Label>
//             <Input
//               type="text"
//               id="mobileNumber"
//               placeholder={t("mobileNumberPlaceholder")}
//               value={mobileNumber}
//               onChange={handleMobileNumberChange}
//               style={{ borderColor: errors.mobileNumber ? "red" : "#ddd" }}
//             />
//             {errors.mobileNumber && (
//               <ErrorText>{errors.mobileNumber}</ErrorText>
//             )}
//           </FormField>

//           <SubmitButton type="submit">{t("nextButton")}</SubmitButton>
//         </form>
//       </Card>

//       <DisclaimerModal
//         isOpen={isModalOpen}
//         onRequestClose={handleCloseModal}
//         ariaHideApp={false}
//         contentLabel={t("disclaimerTitle")}
//       >
//         <ModalHeader>{t("disclaimerTitle")}</ModalHeader>
//         <ModalBody>{t("disclaimerText")}</ModalBody>
//         <SubmitButton onClick={handleCloseModal}>{t("okButton")}</SubmitButton>
//       </DisclaimerModal>
//     </Container>
//   );
// };

// export default SignUpForm;

// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import styled from "styled-components";

// const Container = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   min-height: 100vh;
//   background: url('/assets/NewMiniapp/main-doctor5.jpg') no-repeat center center
//     fixed;
//   background-size: cover;
//   font-family: "Roboto", sans-serif;
// `;

// const Card = styled.div`
//   width: 90%;
//   max-width: 500px;
//   background: rgba(
//     255,
//     255,
//     255,
//     0.9
//   ); /* Slightly transparent white background */
//   border-radius: 20px;
//   box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.15);
//   padding: 30px;
//   text-align: center;
//   position: relative;
//   overflow: hidden;
// `;

// const Header = styled.h1`
//   margin-bottom: 20px;
//   color: #333;
// `;

// const FormField = styled.div`
//   margin-bottom: 20px;
//   text-align: left;
// `;

// const Label = styled.label`
//   display: block;
//   font-size: 16px;
//   color: #333;
//   margin-bottom: 5px;
// `;

// const Input = styled.input`
//   width: 100%;
//   padding: 10px;
//   font-size: 16px;
//   border: 1px solid #ddd;
//   border-radius: 10px;
//   box-sizing: border-box;
//   outline: none;
//   transition: border 0.3s ease;
//   &:focus {
//     border-color: rgb(113, 158, 25);
//   }
// `;

// const ErrorText = styled.div`
//   color: red;
//   font-size: 14px;
// `;

// const SubmitButton = styled.button`
//   background: rgb(113, 158, 25);
//   color: white;
//   border: none;
//   border-radius: 30px;
//   padding: 12px 25px;
//   font-size: 18px;
//   cursor: pointer;
//   transition: background 0.3s ease, transform 0.3s ease;
//   &:hover {
//     background: #0056b3;
//     transform: scale(1.05);
//   }
// `;

// const SignUpForm = () => {
//   const [name, setName] = useState("");
//   const [mobileNumber, setMobileNumber] = useState("93");
//   const [errors, setErrors] = useState({});
//   const navigate = useNavigate();

//   const handleMobileNumberChange = (e) => {
//     const value = e.target.value.replace(/\D/g, "");
//     if (value.length > 12) return;

//     setMobileNumber(value);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     const newErrors = {};
//     if (!name) newErrors.name = "Name is required";
//     if (!mobileNumber || mobileNumber.length !== 12) {
//       newErrors.mobileNumber = "Mobile number must be 12 digits long";
//     }

//     if (Object.keys(newErrors).length > 0) {
//       setErrors(newErrors);
//       return;
//     }
//     navigate("/otp", { state: { mobileNumber, name} });
//   };

//   return (
//     <Container>
//       <Card>
//         <Header>Welcome</Header>
//         <form onSubmit={handleSubmit}>
//           <FormField>
//             <Label htmlFor="name">Name</Label>
//             <Input
//               type="text"
//               id="name"
//               placeholder="Enter your name"
//               value={name}
//               onChange={(e) => setName(e.target.value)}
//               style={{ borderColor: errors.name ? "red" : "#ddd" }}
//             />
//             {errors.name && <ErrorText>{errors.name}</ErrorText>}
//           </FormField>

//           <FormField>
//             <Label htmlFor="mobileNumber">Mobile Number (Example 9378XXXXXXX)</Label>
//             <Input
//               type="text"
//               id="mobileNumber"
//               placeholder="Enter your mobile number"
//               value={mobileNumber}
//               onChange={handleMobileNumberChange}
//               style={{ borderColor: errors.mobileNumber ? "red" : "#ddd" }}
//             />
//             {errors.mobileNumber && (
//               <ErrorText>{errors.mobileNumber}</ErrorText>
//             )}
//           </FormField>

//           <SubmitButton type="submit">Next</SubmitButton>
//         </form>
//       </Card>
//     </Container>
//   );
// };

// export default SignUpForm;
