import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BsDownload } from "react-icons/bs";
import { Container, Row, Col } from "react-bootstrap";
import Header_packages from "../components/Header_packages";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useLoading } from "../contexts/LoadingContext";
import axios from "axios";
import { useTranslation } from "react-i18next"; // Import useTranslation
import "./prescription.css";
import "../i18n"; // Import i18n configuration

const Prescription = () => {
  const [prescriptionData, setPrescriptionData] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const componentRef = useRef();
  const { isLoading, setIsLoading } = useLoading();
  const { t } = useTranslation(); // Initialize useTranslation

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(
          "https://bundleapi.webddocsystems.com/api/Account/PrescriptionHistoryDetials",
          null,
          {
            params: {
              id: id,
            },
          }
        );

        if (response.status === 200 && response.data.responseCode === "0000") {
          setPrescriptionData({
            ...response.data.prescriptions,
            details: response.data.details,
          });
        } else {
          throw new Error("Invalid response format");
        }
      } catch (error) {
        console.error("Error fetching prescription data", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id, setIsLoading]);

  const downloadPdf = async () => {
    try {
      const element = componentRef.current;
      const canvas = await html2canvas(element, {
        scrollY: -window.scrollY,
      });
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const pdf = new jsPDF({
        orientation: "p",
        unit: "px",
        format: "a4",
      });

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = pdfWidth - 20;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      let heightLeft = imgHeight;
      let position = 0;

      const headerText = t("pdfHeader"); // Use translated header text
      const margin = 20;
      pdf.text(headerText, 10, 10);
      pdf.addImage(imgData, "JPEG", 10, margin, imgWidth, imgHeight);
      heightLeft -= pdfHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "JPEG", 10, position + 10, imgWidth, imgHeight);
        heightLeft -= pdfHeight;
      }

      pdf.save("prescription.pdf");
    } catch (error) {
      console.error("Error generating PDF", error);
    }
  };

  return (
    <Container fluid className="overflow-auto">
      <Header_packages title={t("prescriptionDetailTitle")} />
      <div style={{ paddingTop: "50px" }}>
        <Row className="mt-4 p-2" style={{ marginBottom: "100px" }}>
          <div ref={componentRef}>
            <section className="inner-frame1">
              <div className="sub-frame-a1">
                <div className="now-previous-frames">
                  <div className="video-call-frame1">
                    <Col xs={12}>
                      <div className="a-f-b-myco-frames">
                        <Row xs={12}>
                          <Col xs={4}>
                            <p className="diagnosis1">{t("doctor")}</p>
                            <p className="diagnosis1">{t("date")}</p>
                            <p className="diagnosis1">{t("complaint")}</p>
                            <p className="diagnosis1">{t("diagnosis")}</p>
                          </Col>
                          <Col xs={8}>
                            {prescriptionData ? (
                              <>
                                <p className="diagnosisClass">
                                  {prescriptionData.doctorName}
                                </p>
                                <p className="diagnosisClass">
                                  {prescriptionData.datetime}
                                </p>
                                <p className="diagnosisClass">
                                  {prescriptionData.complaint}
                                </p>
                                <p className="diagnosisClass">
                                  {prescriptionData.diagnosis}
                                </p>
                              </>
                            ) : (
                              <p>{t("noDataMessage")}</p> // Display message if no data
                            )}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    {prescriptionData &&
                      prescriptionData.details.map((value) => (
                        <div
                          className="complaint-consultation-test-fr"
                          key={value.medicineName}
                        >
                          <Row xs={12}>
                            <Col xs={4}>
                              <p className="diagnosis1">{t("medicineName")}</p>
                              <p className="diagnosis1">{t("days")}</p>
                            </Col>
                            <Col xs={8}>
                              <p className="diagnosisClass">
                                {value.medicineName}
                              </p>
                              <p className="diagnosisClass">{value.noOfDays}</p>
                            </Col>
                            <Row xs={12}>
                              <Col xs={4}>
                                <p className="noon">{t("morning")}</p>
                              </Col>
                              <Col xs={4}>
                                <p className="noon">{t("noon")}</p>
                              </Col>
                              <Col xs={4}>
                                <p className="noon">{t("evening")}</p>
                              </Col>
                            </Row>
                            <Row xs={12} className="mt-2">
                              <Col xs={4}>
                                <p className="custom-margin">
                                  {value?.morning ?? 0}
                                </p>
                              </Col>
                              <Col xs={4}>
                                <p className="custom-margin">
                                  {value?.noon ?? 0}
                                </p>
                              </Col>
                              <Col xs={4}>
                                <p className="custom-margin">
                                  {value?.evening ?? 0}
                                </p>
                              </Col>
                            </Row>
                            <Row xs={12}>
                              <Col xs={2}>
                                <p className="diagnosis1 text-green">
                                  {t("note")}
                                </p>
                              </Col>
                              <Col xs={10}>
                                <p className="diagnosisClass">
                                  {value.additionalNotes}
                                </p>
                              </Col>
                            </Row>
                          </Row>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="d-flex justify-content-end align-items-center mt-4">
            <BsDownload onClick={downloadPdf} className="navbar-icon" />
          </div>
        </Row>
      </div>
    </Container>
  );
};

export default Prescription;

// import React, { useState, useEffect, useRef } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import { BsDownload } from "react-icons/bs";
// import { Container, Row, Col } from "react-bootstrap";
// import Header_packages from "../components/Header_packages";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
// import { useLoading } from "../contexts/LoadingContext";
// import axios from "axios";
// import "./prescription.css";

// const Prescription = () => {
//   const [prescriptionData, setPrescriptionData] = useState(null);
//   const navigate = useNavigate();
//   const { id } = useParams();
//   const componentRef = useRef();
//   const { isLoading, setIsLoading } = useLoading();

//   useEffect(() => {
//     const fetchData = async () => {
//       setIsLoading(true);
//       try {
//         const response = await axios.post(
//           "https://bundleapi.webddocsystems.com/api/Account/PrescriptionHistoryDetials",
//           null,
//           {
//             params: {
//               id: id,
//             },
//           }
//         );

//         if (response.status === 200 && response.data.responseCode === "0000") {
//           setPrescriptionData({
//             ...response.data.prescriptions,
//             details: response.data.details,
//           });
//         } else {
//           throw new Error("Invalid response format");
//         }
//       } catch (error) {
//         console.error("Error fetching prescription data", error);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchData();
//   }, [id, setIsLoading]);

//   const downloadPdf = async () => {
//     try {
//       const element = componentRef.current;
//       const canvas = await html2canvas(element, {
//         scrollY: -window.scrollY,
//       });
//       const imgData = canvas.toDataURL("image/jpeg", 1.0);
//       const pdf = new jsPDF({
//         orientation: "p",
//         unit: "px",
//         format: "a4",
//       });

//       const pdfWidth = pdf.internal.pageSize.getWidth();
//       const pdfHeight = pdf.internal.pageSize.getHeight();
//       const imgWidth = pdfWidth - 20;
//       const imgHeight = (canvas.height * imgWidth) / canvas.width;

//       let heightLeft = imgHeight;
//       let position = 0;

//       const headerText = "Prescription Details";
//       const margin = 20;
//       pdf.text(headerText, 10, 10);
//       pdf.addImage(imgData, "JPEG", 10, margin, imgWidth, imgHeight);
//       heightLeft -= pdfHeight;

//       while (heightLeft >= 0) {
//         position = heightLeft - imgHeight;
//         pdf.addPage();
//         pdf.addImage(imgData, "JPEG", 10, position + 10, imgWidth, imgHeight);
//         heightLeft -= pdfHeight;
//       }

//       pdf.save("prescription.pdf");
//     } catch (error) {
//       console.error("Error generating PDF", error);
//     }
//   };

//   return (
//     <Container fluid className="overflow-auto">
//       <Header_packages title="Detail" />
//       <div style={{ paddingTop: "50px" }}>
//         <Row className="mt-4 p-2" style={{ marginBottom: "100px" }}>
//           <div ref={componentRef}>
//             <section className="inner-frame1">
//               <div className="sub-frame-a1">
//                 <div className="now-previous-frames">
//                   <div className="video-call-frame1">
//                     <Col xs={12}>
//                       <div className="a-f-b-myco-frames">
//                         <Row xs={12}>
//                           <Col xs={4}>
//                             <p className="diagnosis1">Doctor</p>
//                             <p className="diagnosis1">Date</p>
//                             <p className="diagnosis1">Complaint</p>
//                             <p className="diagnosis1">Diagnosis</p>
//                           </Col>
//                           <Col xs={8}>
//                             {prescriptionData && (
//                               <>
//                                 <p className="diagnosisClass">
//                                   {prescriptionData.doctorName}
//                                 </p>
//                                 <p className="diagnosisClass">
//                                   {prescriptionData.datetime}
//                                 </p>
//                                 <p className="diagnosisClass">
//                                   {prescriptionData.complaint}
//                                 </p>
//                                 <p className="diagnosisClass">
//                                   {prescriptionData.diagnosis}
//                                 </p>
//                               </>
//                             )}
//                           </Col>
//                         </Row>
//                       </div>
//                     </Col>
//                     {prescriptionData &&
//                       prescriptionData.details.map((value) => (
//                         <div
//                           className="complaint-consultation-test-fr"
//                           key={value.medicineName}
//                         >
//                           <Row xs={12}>
//                             <Col xs={4}>
//                               <p className="diagnosis1">Medicine Name</p>
//                               <p className="diagnosis1">Days</p>
//                             </Col>
//                             <Col xs={8}>
//                               <p className="diagnosisClass">
//                                 {value.medicineName}
//                               </p>
//                               <p className="diagnosisClass">{value.noOfDays}</p>
//                             </Col>
//                             <Row xs={12}>
//                               <Col xs={4}>
//                                 <p className="noon">Morning</p>
//                               </Col>
//                               <Col xs={4}>
//                                 <p className="noon">Noon</p>
//                               </Col>
//                               <Col xs={4}>
//                                 <p className="noon">Evening</p>
//                               </Col>
//                             </Row>
//                             <Row xs={12} className="mt-2">
//                               <Col xs={4}>
//                                 <p className="custom-margin">
//                                   {value?.morning ?? 0}
//                                 </p>
//                               </Col>
//                               <Col xs={4}>
//                                 <p className="custom-margin">
//                                   {value?.noon ?? 0}
//                                 </p>
//                               </Col>
//                               <Col xs={4}>
//                                 <p className="custom-margin">
//                                   {value?.evening ?? 0}
//                                 </p>
//                               </Col>
//                             </Row>
//                             <Row xs={12}>
//                               <Col xs={2}>
//                                 <p className="diagnosis1 text-green">Note:</p>
//                               </Col>
//                               <Col xs={10}>
//                                 <p className="diagnosisClass">
//                                   {value.additionalNotes}
//                                 </p>
//                               </Col>
//                             </Row>
//                           </Row>
//                         </div>
//                       ))}
//                   </div>
//                 </div>
//               </div>
//             </section>
//           </div>
//           <div className="d-flex justify-content-end align-items-center mt-4">
//             <BsDownload onClick={downloadPdf} className="navbar-icon" />
//           </div>
//         </Row>
//       </div>
//     </Container>
//   );
// };

// export default Prescription;
