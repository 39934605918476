// src/components/Historycard.js
import React from "react";
import "../../src/style.css";

const Historycard = ({ image, title }) => {
  return (
    <div className="custom-card mb-2 p-3">
      <div className="row align-items-center">
      <div className="col-5">
          <div className="text-start card-body">
            <p className="card-title">{title}</p>
          </div>
        </div>
        <div className="col-7">
          <img
            src={image}
            className="card-img-top"
            alt={title}
          />
        </div>
       
      </div>
    </div>
  );
};

export default Historycard;
