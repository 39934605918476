import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useLoading } from "../contexts/LoadingContext";
import Header_packages from "../components/Header_packages";
import axios from "axios";
import { useTranslation } from "react-i18next"; // Import useTranslation
import "./prescriptions-list.css";
import "../i18n"; // Import i18n configuration

const PrescriptionsList = () => {
  const [prescriptionListData, setPrescriptionListData] = useState([]);
  const [loadingMore, setLoadingMore] = useState(false);

  const navigate = useNavigate();
  const { isLoading, setIsLoading } = useLoading();
  const { t } = useTranslation(); // Initialize useTranslation
  const session = JSON.parse(sessionStorage.getItem("userData"));
  const sessionUserId = session?.applicationUserId || null;

  useEffect(() => {
    if (sessionUserId) {
      const fetchData = async () => {
        setLoadingMore(true);
        setIsLoading(true);
        try {
          const response = await axios.post(
            "https://bundleapi.webddocsystems.com/api/Account/PrescriptionHistory",
            null,
            {
              params: {
                patientId: sessionUserId,
              },
            }
          );

          if (
            response.status === 200 &&
            response.data.responseCode === "0000"
          ) {
            setPrescriptionListData(response.data.prescriptions);
          } else {
            console.error("Invalid response format or responseCode");
          }
        } catch (error) {
          console.error("Error fetching prescriptions:", error);
        } finally {
          setLoadingMore(false);
          setIsLoading(false);
        }
      };
      fetchData();
    }
  }, [sessionUserId, setIsLoading]);

  const onAudioVideoCallClick = useCallback(
    (id) => {
      navigate(`/prescription/${id}`);
    },
    [navigate]
  );

  return (
    <Container fluid>
      <Header_packages title={t("prescriptionsListTitle")} />
      <div style={{ paddingTop: "60px" }}>
        <Row className="mt-4 p-2" style={{ marginBottom: "100px" }}>
          {prescriptionListData.length > 0 ? (
            <>
              {prescriptionListData.map((value) => (
                <Col key={value.id} lg={4} md={6} className="mb-3">
                  <Card
                    className="h-100"
                    onClick={() => onAudioVideoCallClick(value.id)}
                    style={{
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    }}
                  >
                    <Card.Body className="cutom-card-body">
                      <Card.Text>
                        <Row>
                          <Col xs={10}>
                            <div className="consultation-info">
                              <span className="text-styling-one text-red">
                                {value.datetime}
                              </span>
                            </div>
                            <div className="complaint-info">
                              <span className="text-styling-one">
                                {value.complaint}
                              </span>
                            </div>
                            <div className="doctor-info">
                              <span className="text-styling-one">
                                {value.drname &&
                                  value.drname.charAt(0).toUpperCase() +
                                    value.drname.slice(1)}
                              </span>
                            </div>
                          </Col>
                          <Col
                            className="d-flex justify-content-end align-items-center"
                            lg={2}
                            md={2}
                            sm={2}
                            xs={2}
                          >
                            <div className="parent-frame-set">
                              <div className="parent-frame-set-child"></div>
                              <img
                                className="parent-frame-set-item"
                                loading="eager"
                                alt=""
                                src="/arrow-2.svg"
                              />
                            </div>
                          </Col>
                        </Row>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </>
          ) : (
            <div className="custom-typography1 text-align-center">
              <p>{t("noDataMessage")}</p> {/* Use translated string */}
            </div>
          )}
        </Row>
      </div>
    </Container>
  );
};

export default PrescriptionsList;

// import React, { useState, useEffect, useCallback } from "react";
// import { useNavigate } from "react-router-dom";
// import { Container, Row, Col, Card } from "react-bootstrap";
// import { useLoading } from "../contexts/LoadingContext";
// import Header_packages from "../components/Header_packages";
// import axios from "axios";
// import "./prescriptions-list.css";

// const PrescriptionsList = () => {
//   const [prescriptionListData, setPrescriptionListData] = useState([]);
//   const [loadingMore, setLoadingMore] = useState(false);

//   const navigate = useNavigate();
//   const { isLoading, setIsLoading } = useLoading();
//   const session = JSON.parse(sessionStorage.getItem("userData"));
//   const sessionUserId = session?.applicationUserId || null;

//   useEffect(() => {
//     if (sessionUserId) {
//       const fetchData = async () => {
//         setLoadingMore(true);
//         setIsLoading(true);
//         try {
//           const response = await axios.post(
//             "https://bundleapi.webddocsystems.com/api/Account/PrescriptionHistory",
//             null,
//             {
//               params: {
//                 patientId: sessionUserId,
//               },
//             }
//           );

//           if (response.status === 200 && response.data.responseCode === "0000") {
//             setPrescriptionListData(response.data.prescriptions);
//           } else {
//             console.error("Invalid response format or responseCode");
//           }
//         } catch (error) {
//           console.error("Error fetching prescriptions:", error);
//         } finally {
//           setLoadingMore(false);
//           setIsLoading(false);
//         }
//       };
//       fetchData();
//     }
//   }, [sessionUserId, setIsLoading]);

//   const onAudioVideoCallClick = useCallback(
//     (id) => {
//       navigate(`/prescription/${id}`);
//     },
//     [navigate]
//   );

//   return (
//     <Container fluid>
//       <Header_packages title="Prescription" />
//       <div style={{ paddingTop: "60px" }}>
//         <Row className="mt-4 p-2" style={{ marginBottom: "100px" }}>
//           {prescriptionListData.length > 0 ? (
//             <>
//               {prescriptionListData.map((value) => (
//                 <Col key={value.id} lg={4} md={6} className="mb-3">
//                   <Card
//                     className="h-100"
//                     onClick={() => onAudioVideoCallClick(value.id)}
//                     style={{
//                       boxShadow:
//                         "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
//                     }}
//                   >
//                     <Card.Body className="cutom-card-body">
//                       <Card.Text>
//                         <Row>
//                           <Col xs={10}>
//                             <div className="consultation-info">
//                               <span className="text-styling-one text-red">
//                                 {value.datetime}
//                               </span>
//                             </div>
//                             <div className="complaint-info">
//                               <span className="text-styling-one">
//                                 {value.complaint}
//                               </span>
//                             </div>
//                             <div className="doctor-info">
//                               <span className="text-styling-one">
//                                 {value.drname &&
//                                   value.drname.charAt(0).toUpperCase() +
//                                     value.drname.slice(1)}
//                               </span>
//                             </div>
//                           </Col>
//                           <Col
//                             className="d-flex justify-content-end align-items-center"
//                             lg={2}
//                             md={2}
//                             sm={2}
//                             xs={2}
//                           >
//                             <div className="parent-frame-set">
//                               <div className="parent-frame-set-child"></div>
//                               <img
//                                 className="parent-frame-set-item"
//                                 loading="eager"
//                                 alt=""
//                                 src="/arrow-2.svg"
//                               />
//                             </div>
//                           </Col>
//                         </Row>
//                       </Card.Text>
//                     </Card.Body>
//                   </Card>
//                 </Col>
//               ))}
//             </>
//           ) : (
//             <div className="custom-typography1 text-align-center">
//               <p>There is no data.</p>
//             </div>
//           )}
//         </Row>
//       </div>
//     </Container>
//   );
// };

// export default PrescriptionsList;
