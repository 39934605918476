import React, { useEffect, useState } from "react";
import { useLoading } from "../contexts/LoadingContext";
import PackageCard from "../components/PackageCard";
import Header from "../components/Header";
import Doctors_db from "../components/Doctors_db";
import HealthTips from "../components/HealthTips";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const Main = () => {
  const { isLoading, setIsLoading } = useLoading();
  const [isUserData, setUserData] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const sessionUser = JSON.parse(sessionStorage.getItem("userData"));
    setUserData(sessionUser);
  }, []);

  useEffect(() => {
    const sessionUser = JSON.parse(sessionStorage.getItem("userData"));
    const redirectFromVideoCall = sessionStorage.getItem(
      "redirectFromVideoCall"
    );

    console.log(redirectFromVideoCall);

    if (redirectFromVideoCall === "true") {
      Swal.fire({
        icon: "info",
        title: t("consultationAlertTitle"), 
        text: t("consultationAlertText"), 
        confirmButtonText: t("consultationAlertButton"),
      }).then(() => {
        sessionUser.package = "";
        sessionStorage.setItem("userData", JSON.stringify(sessionUser));
        sessionStorage.removeItem("redirectFromVideoCall");
      });
    }
  }, [t]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Header userSession={isUserData} />
      <div className="container mt-2">
        <div className="row mb-3">
          <div className="col-12">
            <PackageCard userData={isUserData}
              icon="assets/NewMiniapp/health-insurance.png"
              label={t("healthInsuranceLabel")}
            />
          </div>
        </div>
        <hr className="bold-hr" />
        <Doctors_db />
        <hr className="bold-hr" />
        <HealthTips />
      </div>
    </>
  );
};

export default Main;

// import React, { useEffect, useState } from "react";
// import { useLoading } from "../contexts/LoadingContext";
// import PackageCard from "../components/PackageCard";
// import Header from "../components/Header";
// import Doctors_db from "../components/Doctors_db";
// import HealthTips from "../components/HealthTips";
// import Swal from "sweetalert2";

// const Main = () => {
//   const { isLoading, setIsLoading } = useLoading();
//   const [isUserData, setUserData] = useState(null);

//   useEffect(() => {
//     const sessionUser = JSON.parse(sessionStorage.getItem("userData"));
//     setUserData(sessionUser);
//   }, [isUserData]);

//   useEffect(() => {
//     const sessionUser = JSON.parse(sessionStorage.getItem("userData"));
//     const redirectFromVideoCall = sessionStorage.getItem(
//       "redirectFromVideoCall"
//     );

//     if (redirectFromVideoCall === "true") {
//       Swal.fire({
//         icon: "info",
//         title: "Consultation",
//         text: "The prescription/consultation will be available in the medical history section within 5 minutes.",
//         confirmButtonText: "Ok",
//       }).then(() => {
//         sessionUser.package = "";
//         sessionStorage.setItem("userData", JSON.stringify(sessionUser));
//         sessionStorage.removeItem("redirectFromVideoCall");
//         window.location.reload();
//       });
//     }
//   }, []);

//   if (isLoading) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <>
//       <Header userSession={isUserData} />
//       <div className="container mt-2">
//         <div className="row mb-3">
//           <div className="col-12">
//             <PackageCard
//               icon="assets/NewMiniapp/health-insurance.png"
//               label="Health Insurance"
//             />
//           </div>
//         </div>
//         <hr className="bold-hr"></hr>
//         <Doctors_db />
//         <hr className="bold-hr"></hr>
//         <HealthTips />
//       </div>
//     </>
//   );
// };

// export default Main;
