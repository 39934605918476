import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import Header_packages from "../components/Header_packages";
import "bootstrap/dist/css/bootstrap.min.css";
import styled from "styled-components";
import { useTranslation } from "react-i18next"; // Import useTranslation
import "../i18n"; // Import i18n configuration

// Styled components
const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f9f9f9;
`;

const FormCard = styled.div`
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  padding: 30px;
  max-width: 600px;
  width: 100%;
`;

const Header = styled.h2`
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
`;

const StyledButton = styled(Button)`
  background-color: rgb(113, 158, 25);
  border: none;
  border-radius: 30px;
  padding: 12px;
  font-size: 16px;
  width: 100%;
  &:hover {
    background-color: #0056b3;
  }
`;

const AddVitals = () => {
  const { t } = useTranslation(); // Initialize useTranslation

  const [formData, setFormData] = useState({
    bloodPressure: "",
    bloodSugar: "",
    height: "",
    oxygenSaturation: "",
    pulseRate: "",
    respiratoryRate: "",
    weight: "",
    temperature: "",
    patientId: "", // Set patientId here
  });

  const [responseMessage, setResponseMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    // Retrieve patientId from session storage
    const session = JSON.parse(sessionStorage.getItem("userData"));

    if (session) {
      setFormData((prevData) => ({
        ...prevData,
        patientId: session.applicationUserId,
        companyName: "Afghan", // Static value for companyName
      }));
    } else {
      // Handle case when patientId is not in session storage
      setErrorMessage(t("patientIdNotFound"));
    }
  }, [t]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://bundleapi.webddocsystems.com/api/Account/Addvitals",
        formData
      );
      if (response.status === 200) {
        setResponseMessage(t("vitalsAddedSuccess"));
        setErrorMessage("");
      }
    } catch (error) {
      setErrorMessage(t("errorAddingVitals"));
      setResponseMessage("");
    }
  };

  return (
    <PageContainer>
      <Header_packages title={t("addVitalsTitle")} />
      <FormCard className="m-3 mt-5">
        {responseMessage && <Alert variant="success">{responseMessage}</Alert>}
        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formBloodPressure" className="mb-3">
            <Form.Label>{t("bloodPressure")}</Form.Label>
            <Form.Control
              type="text"
              name="bloodPressure"
              value={formData.bloodPressure}
              onChange={handleChange}
              placeholder={t("bloodPressure")}
              required
              style={{ borderRadius: "8px", padding: "10px" }}
            />
          </Form.Group>
          <Form.Group controlId="formBloodSugar" className="mb-3">
            <Form.Label>{t("bloodSugar")}</Form.Label>
            <Form.Control
              type="text"
              name="bloodSugar"
              value={formData.bloodSugar}
              onChange={handleChange}
              placeholder={t("bloodSugar")}
              required
              style={{ borderRadius: "8px", padding: "10px" }}
            />
          </Form.Group>
          <Form.Group controlId="formHeight" className="mb-3">
            <Form.Label>{t("height")}</Form.Label>
            <Form.Control
              type="text"
              name="height"
              value={formData.height}
              onChange={handleChange}
              placeholder={t("height")}
              required
              style={{ borderRadius: "8px", padding: "10px" }}
            />
          </Form.Group>
          <Form.Group controlId="formOxygenSaturation" className="mb-3">
            <Form.Label>{t("oxygenSaturation")}</Form.Label>
            <Form.Control
              type="text"
              name="oxygenSaturation"
              value={formData.oxygenSaturation}
              onChange={handleChange}
              placeholder={t("oxygenSaturation")}
              required
              style={{ borderRadius: "8px", padding: "10px" }}
            />
          </Form.Group>
          <Form.Group controlId="formPulseRate" className="mb-3">
            <Form.Label>{t("pulseRate")}</Form.Label>
            <Form.Control
              type="text"
              name="pulseRate"
              value={formData.pulseRate}
              onChange={handleChange}
              placeholder={t("pulseRate")}
              required
              style={{ borderRadius: "8px", padding: "10px" }}
            />
          </Form.Group>
          <Form.Group controlId="formRespiratoryRate" className="mb-3">
            <Form.Label>{t("respiratoryRate")}</Form.Label>
            <Form.Control
              type="text"
              name="respiratoryRate"
              value={formData.respiratoryRate}
              onChange={handleChange}
              placeholder={t("respiratoryRate")}
              required
              style={{ borderRadius: "8px", padding: "10px" }}
            />
          </Form.Group>
          <Form.Group controlId="formWeight" className="mb-3">
            <Form.Label>{t("weight")}</Form.Label>
            <Form.Control
              type="text"
              name="weight"
              value={formData.weight}
              onChange={handleChange}
              placeholder={t("weight")}
              required
              style={{ borderRadius: "8px", padding: "10px" }}
            />
          </Form.Group>
          <Form.Group controlId="formWeight" className="mb-3">
            <Form.Label>Temperature</Form.Label>
            <Form.Control
              type="text"
              name="temperature"
              value={formData.temperature}
              onChange={handleChange}
              placeholder="temperature"
              required
              style={{ borderRadius: "8px", padding: "10px" }}
            />
          </Form.Group>
          <StyledButton type="submit">{t("submitButton")}</StyledButton>
        </Form>
      </FormCard>
    </PageContainer>
  );
};

export default AddVitals;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
// import Header_packages from "../components/Header_packages";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import styled from 'styled-components';

// // Styled components
// const PageContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   min-height: 100vh;
//   background-color: #f9f9f9;
// `;

// const FormCard = styled.div`
//   background: #fff;
//   border-radius: 12px;
//   box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
//   padding: 30px;
//   max-width: 600px;
//   width: 100%;
// `;

// const Header = styled.h2`
//   text-align: center;
//   color: #333;
//   margin-bottom: 20px;
//   font-size: 24px;
//   font-weight: 600;
// `;

// const StyledButton = styled(Button)`
//   background-color: rgb(113, 158, 25);
//   border: none;
//   border-radius: 30px;
//   padding: 12px;
//   font-size: 16px;
//   width: 100%;
//   &:hover {
//     background-color: #0056b3;
//   }
// `;

// const AddVitals = () => {
//   const [formData, setFormData] = useState({
//     bloodPressure: '',
//     bloodSugar: '',
//     height: '',
//     oxygenSaturation: '',
//     pulseRate: '',
//     respiratoryRate: '',
//     weight: '',
//     patientId: '' // Set patientId here
//   });

//   const [responseMessage, setResponseMessage] = useState('');
//   const [errorMessage, setErrorMessage] = useState('');

//   useEffect(() => {
//     // Retrieve patientId from session storage
//     const session = JSON.parse(sessionStorage.getItem("userData"));

//     if (session) {
//       setFormData(prevData => ({
//         ...prevData,
//         patientId: session.applicationUserId,
//         companyName: 'Afghan' // Static value for companyName
//       }));
//     } else {
//       // Handle case when patientId is not in session storage
//       setErrorMessage('Patient ID not found. Please log in again.');
//     }
//   }, []);

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post('https://bundleapi.webddocsystems.com/api/Account/Addvitals', formData);
//       if (response.status === 200) {
//         setResponseMessage('Vitals added successfully!');
//         setErrorMessage('');
//       }
//     } catch (error) {
//       setErrorMessage('Error adding vitals. Please try again.');
//       setResponseMessage('');
//     }
//   };

//   return (
//     <PageContainer>
//       <Header_packages title="Add Vitals"/>
//       <FormCard className='m-3'>
//         {/* <Header>Add Vitals</Header> */}
//         {responseMessage && <Alert variant="success">{responseMessage}</Alert>}
//         {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
//         <Form onSubmit={handleSubmit}>
//           <Form.Group controlId="formBloodPressure" className="mb-3">
//             <Form.Label>Blood Pressure</Form.Label>
//             <Form.Control
//               type="text"
//               name="bloodPressure"
//               value={formData.bloodPressure}
//               onChange={handleChange}
//               placeholder="Enter blood pressure"
//               required
//               style={{ borderRadius: '8px', padding: '10px' }}
//             />
//           </Form.Group>
//           <Form.Group controlId="formBloodSugar" className="mb-3">
//             <Form.Label>Blood Sugar</Form.Label>
//             <Form.Control
//               type="text"
//               name="bloodSugar"
//               value={formData.bloodSugar}
//               onChange={handleChange}
//               placeholder="Enter blood sugar level"
//               required
//               style={{ borderRadius: '8px', padding: '10px' }}
//             />
//           </Form.Group>
//           <Form.Group controlId="formHeight" className="mb-3">
//             <Form.Label>Height</Form.Label>
//             <Form.Control
//               type="text"
//               name="height"
//               value={formData.height}
//               onChange={handleChange}
//               placeholder="Enter height"
//               required
//               style={{ borderRadius: '8px', padding: '10px' }}
//             />
//           </Form.Group>
//           <Form.Group controlId="formOxygenSaturation" className="mb-3">
//             <Form.Label>Oxygen Saturation</Form.Label>
//             <Form.Control
//               type="text"
//               name="oxygenSaturation"
//               value={formData.oxygenSaturation}
//               onChange={handleChange}
//               placeholder="Enter oxygen saturation level"
//               required
//               style={{ borderRadius: '8px', padding: '10px' }}
//             />
//           </Form.Group>
//           <Form.Group controlId="formPulseRate" className="mb-3">
//             <Form.Label>Pulse Rate</Form.Label>
//             <Form.Control
//               type="text"
//               name="pulseRate"
//               value={formData.pulseRate}
//               onChange={handleChange}
//               placeholder="Enter pulse rate"
//               required
//               style={{ borderRadius: '8px', padding: '10px' }}
//             />
//           </Form.Group>
//           <Form.Group controlId="formRespiratoryRate" className="mb-3">
//             <Form.Label>Respiratory Rate</Form.Label>
//             <Form.Control
//               type="text"
//               name="respiratoryRate"
//               value={formData.respiratoryRate}
//               onChange={handleChange}
//               placeholder="Enter respiratory rate"
//               required
//               style={{ borderRadius: '8px', padding: '10px' }}
//             />
//           </Form.Group>
//           <Form.Group controlId="formWeight" className="mb-3">
//             <Form.Label>Weight</Form.Label>
//             <Form.Control
//               type="text"
//               name="weight"
//               value={formData.weight}
//               onChange={handleChange}
//               placeholder="Enter weight"
//               required
//               style={{ borderRadius: '8px', padding: '10px' }}
//             />
//           </Form.Group>
//           <StyledButton type="submit">Add Vitals</StyledButton>
//         </Form>
//       </FormCard>
//     </PageContainer>
//   );
// };

// export default AddVitals;
