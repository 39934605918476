import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../pages/Paymentscreen.css';
import Health_component from '../components/health_component';
import Header_packages from '../components/Header_packages';
import { useTranslation } from 'react-i18next';

const PaymentScreen = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Header_packages title={t('call_package')} />
      <Health_component />
    </div>
  );
};

export default PaymentScreen;




// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import '../pages/Paymentscreen.css';
// import Health_component from '../components/health_component'
// import Header_packages from '../components/Header_packages';

// const PaymentScreen = () => {
//     return (
//      <div>
//       <Header_packages title="Call Pacakge" />
//       <Health_component />
//      </div>
//     );
//   };
  
//   export default PaymentScreen;
